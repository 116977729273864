import { CLEAR_POSTS, GET_POSTS, CREATE_POST, SET_POSTS_LOADING } from '../types';

const initialState = {
  posts: {},
  post: {},
  loading: true
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_POSTS:
      return {
        posts: payload,
        loading: false
      };
    case CLEAR_POSTS:
      return {
        posts: {},
        loading: false,
      };
    case CREATE_POST:
      return {
        post: payload,
        loading: false
      };
    case SET_POSTS_LOADING:
      return {
        ...state,
        loading: payload
      };

    default:
      return state;
  }
}
