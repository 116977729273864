/* eslint-disable import/prefer-default-export */
import axios from "axios";

import {
  CLEAR_POSTS,
  GET_POSTS,
  SET_POSTS_LOADING,
  GET_ERRORS,
  CLEAR_ERRORS,
} from "../types";

export const setLoading = (val = true) => async (dispatch) => {
  dispatch({
    type: SET_POSTS_LOADING,
    payload: val,
  });
};

export const getPosts = (page = 1, title, order) => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
  dispatch(setLoading());
  const params = {
    page,
    title,
    order,
  };

  try {
    const res = await axios.get("/api/posts", { params });
    dispatch({
      type: GET_POSTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: CLEAR_POSTS });
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const createPost = (
  title,
  postUrl,
  slug,
  content,
  categoryId,
  tags,
  history
) => async (dispatch) => {
  dispatch(setLoading());
  const payload = {
    title,
    postUrl,
    slug,
    content,
    categoryId,
    tags,
  };
  const url = "/api/posts";
  try {
    const res = await axios.post(url, payload);
    dispatch({
      type: GET_POSTS,
      payload: res.data,
    });
    history.push("/dashboard/posts");
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
