import { TextField } from '@mui/material';
import { alignmentOptions, getURLError } from 'src/utils';
import styled from 'styled-components';
import CustomMUISelect from '../components/CustomMUISelect';

const VideoModule = ({ alignment, url, handleChange, showError }) => {
  let urlError = getURLError(showError, url);
  return (
    <>
      <CustomMUISelect
        name="alignment"
        label="Alignment"
        options={alignmentOptions}
        value={alignment}
        onChange={handleChange}
      />
      <Input
        error={urlError}
        autoFocus
        label="Url"
        variant="outlined"
        name="url"
        value={url}
        onChange={handleChange}
        helperText={urlError}
      />
    </>
  );
};

export default VideoModule;

const Input = styled(TextField)`
  width: 100%;
  margin: 15px 0;
`;
