import { Chip, Typography } from '@mui/material';
import React from 'react';

export default function Header({ id, type, size, text, text_fr }) {
  return (
    <>
      <Chip label={size.toUpperCase()} color="primary" />
      <Typography
        id={id}
        type={type}
        variant={size}
        component={'h2'}
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        {text ? text : 'Add header'}
      </Typography>
      {text_fr}
    </>
  );
}
