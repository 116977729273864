import {
  Alert,
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import ReorderButtons from '../components/ReorderButtons';
const options = [
  {
    id: 'category',
    name: 'Categories',
  },
  {
    id: 'subcategory',
    name: 'Subcategories',
  },
];

const layoutOptions = [
  {
    id: 'smallThumbnails',
    name: 'Small Thumbnails',
  },
  {
    id: 'largeThumbnails',
    name: 'Large Thumbnails',
  },
];

const limitOptions = Array.from({ length: 20 }, (e, i) => i + 1);

const CategoryCarouselModule = ({
  limit,
  entity,
  layout,
  items,
  showError,
  handleChange,
  handleEntityChange,
  handleLimitChange,
  handleItemSelect,
  handleAddDiscount,
  handleRemoveItem,
  handleMoveDownItem,
  handleMoveUpItem,
}) => {
  const [categoryText, setCategoryText] = useState('');
  const [subcategoryText, setSubcategoryText] = useState('');
  const [selectedCategory, setselectedCategory] = useState(null);

  const categoryState = useSelector((state) => state.categories);

  return (
    <>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="entity-select">Entity</InputLabel>
        <Select
          autoFocus
          name="entity"
          labelId="entity-select"
          label="Type"
          value={entity}
          onChange={handleEntityChange}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="limit-select">Limit</InputLabel>
        <Select
          labelId="limit-select"
          name="limit"
          label="Type"
          value={limit}
          onChange={handleLimitChange}
        >
          {limitOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="layout-select">Layout</InputLabel>
        <Select
          labelId="layout-select"
          name="layout"
          label="Type"
          value={layout}
          onChange={handleChange}
        >
          {layoutOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {categoryState.loading && <CircularProgress size={20} />}

      {categoryState.dropdown && !categoryState.loading ? (
        entity === 'category' ? (
          <Autocomplete
            disabled={limit === items.length}
            value={null}
            onChange={(event, newValue) => {
              handleItemSelect(newValue, items.length);
              setCategoryText('');
            }}
            inputValue={categoryText}
            onInputChange={(event, newInputValue) => {
              setCategoryText(newInputValue);
            }}
            id="combo-box-business"
            options={categoryState.dropdown.filter(
              (option) => !items.find((op) => op == option.id)
            )}
            getOptionLabel={(item) => item.name}
            sx={{ width: '100%', marginBottom: 2 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={`${items.length} Category(s) Selected`}
              />
            )}
          />
        ) : (
          <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
            <Autocomplete
              disabled={limit === items.length}
              value={selectedCategory}
              onChange={(event, newValue) => {
                setselectedCategory(newValue);
                setCategoryText('');
              }}
              inputValue={categoryText}
              onInputChange={(event, newInputValue) => {
                setCategoryText(newInputValue);
              }}
              id="combo-box-business"
              options={categoryState.dropdown}
              getOptionLabel={(item) => item.name}
              sx={{ flex: 1 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Category" />
              )}
            />
            {selectedCategory ? (
              <Autocomplete
                disabled={limit === items.length}
                value={null}
                onChange={(event, newValue) => {
                  handleItemSelect(newValue, items.length);
                  setSubcategoryText('');
                }}
                inputValue={subcategoryText}
                onInputChange={(event, newInputValue) => {
                  setSubcategoryText(newInputValue);
                }}
                id="combo-box-business"
                options={selectedCategory.subcategories.filter(
                  (option) => !items.find((op) => op == option.id)
                )}
                getOptionLabel={(item) => item.name}
                sx={{ flex: 1 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={`${items.length} Subcategory(s) Selected`}
                  />
                )}
              />
            ) : null}
          </Stack>
        )
      ) : null}

      {items && categoryState.dropdown
        ? items.map((cat, index) => (
            <Stack
              key={index}
              direction="row"
              sx={{ padding: 1 }}
              spacing={2}
              alignItems="baseline"
              justifyContent="space-between"
            >
              {entity === 'category' ? (
                <div style={{ width: 'calc(100% - 225px)' }}>
                  {categoryState.dropdown.find((op) => op.id === cat).name}
                </div>
              ) : null}
              {entity === 'subcategory' ? (
                <div style={{ width: 'calc(100% - 225px)' }}>
                  {categoryState.subcategories.find((op) => op.id === cat).name}
                </div>
              ) : null}
              <Stack direction="row" alignItems="center" sx={{ width: 150 }}>
                <CloseIcon
                  onClick={() => {
                    handleRemoveItem(index);
                  }}
                />
                <ReorderButtons
                  index={index}
                  items={items}
                  handleMoveDownItem={handleMoveDownItem}
                  handleMoveUpItem={handleMoveUpItem}
                />
              </Stack>
            </Stack>
          ))
        : null}
      {showError ? (
        <Alert severity="error">
          Please select {limit} {entity}
        </Alert>
      ) : null}
    </>
  );
};

export default CategoryCarouselModule;
