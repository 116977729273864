import styled from '@emotion/styled';
import {
  Alert,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';
import isURL from 'validator/lib/isURL';
import ColorPicker from '../../../components/Inputs/ColorPicker';
import ImageSelect from '../../../components/Inputs/ImageSelect';
import ReorderButtons from '../components/ReorderButtons';
import Wysiwyg from '../components/Wysiwyg';

const layoutOptions = [
  {
    id: 'smallThumbnails',
    name: 'Small Thumbnails',
  },
  {
    id: 'largeThumbnails',
    name: 'Large Thumbnails',
  },
  {
    id: 'twoColumns',
    name: 'Two Columns',
  },
];

const limitOptions = Array.from({ length: 20 }, (e, i) => i + 1);

const Input = styled(TextField)`
  width: 100%;
  margin: 15px 0;
`;

export default function ImageCarouselModule({
  images,
  layout,
  limit,
  orientation,
  title,
  title_fr,
  showError,
  handleChange,
  handleChangeRemoveError,
  handleLimitChange,
  handleImageChange,
  handleImagesChange,
  handleRemoveItem,
  handleMoveDownItem,
  handleMoveUpItem,
}) {
  return (
    <div>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="orientation-select">Orientation</InputLabel>
        <Select
          labelId="orientation-select"
          label="Orientation"
          name="orientation"
          value={orientation}
          onChange={handleChange}
        >
          <MenuItem value="left">Left</MenuItem>
          <MenuItem value="right">Right</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="limit-select">Limit</InputLabel>
        <Select
          labelId="limit-select"
          autoFocus
          name="limit"
          label="Type"
          value={limit}
          onChange={handleLimitChange}
        >
          {limitOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="layout-select">Layout</InputLabel>
        <Select
          labelId="layout-select"
          name="layout"
          label="Type"
          value={layout}
          onChange={handleChange}
        >
          {layoutOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack
        spacing={2}
        alignItems="baseline"
        justifyContent="space-between"
        direction="row"
      >
        <Input
          error={
            showError && layout === 'twoColumns' && (!title || !title.trim())
          }
          label="Title"
          variant="outlined"
          name="title"
          value={title}
          onChange={handleChangeRemoveError}
          helperText={
            showError && layout === 'twoColumns' && (!title || !title.trim())
              ? 'Required'
              : null
          }
        />
        <Input
          label="Title (French)"
          variant="outlined"
          name="title_fr"
          value={title_fr}
          onChange={handleChange}
        />
      </Stack>
      {images
        ? images.map((image, index) => (
            <div key={index}>
              {images.length > 1 ? (
                <Divider textAlign="center">
                  <Chip label={index + 1} />
                </Divider>
              ) : null}
              <Stack direction="row" justifyContent="end">
                <ReorderButtons
                  index={index}
                  items={images}
                  handleMoveDownItem={handleMoveDownItem}
                  handleMoveUpItem={handleMoveUpItem}
                />
              </Stack>
              <TextField
                fullWidth
                error={showError && image.url && !isURL(image.url)}
                sx={{ margin: '15px 0' }}
                label="URL"
                variant="outlined"
                value={image.url}
                onChange={(e) =>
                  handleImagesChange(e.target.value, index, 'url')
                }
                helperText={
                  showError && image.url && !isURL(image.url)
                    ? 'Must be a valid URL'
                    : null
                }
              />
              <TextField
                fullWidth
                sx={{ margin: '15px 0' }}
                error={showError && layout === 'twoColumns' && !image.heading}
                label="Heading"
                variant="outlined"
                value={image.heading}
                onChange={(e) =>
                  handleImagesChange(e.target.value, index, 'heading')
                }
                helperText={
                  showError && layout === 'twoColumns' && !image.heading
                    ? 'Required'
                    : null
                }
              />
              <TextField
                fullWidth
                sx={{ margin: '15px 0' }}
                label="Heading (fr)"
                variant="outlined"
                value={image.heading_fr}
                onChange={(e) =>
                  handleImagesChange(e.target.value, index, 'heading_fr')
                }
              />
              {layout === 'twoColumns' ? (
                <ColorPicker
                  color={image.color}
                  handleChange={(value) =>
                    handleImagesChange(value, index, 'color')
                  }
                />
              ) : null}
              <Stack
                direction="row"
                sx={{ padding: 1 }}
                spacing={2}
                alignItems="baseline"
                justifyContent="space-between"
              >
                <ImageSelect
                  initialImage={image.mediaItem}
                  label="Image"
                  name="headerImage"
                  type="Thumbnail"
                  handleSelect={(value, name) =>
                    handleImageChange(value, index, 'id')
                  }
                  error={
                    showError && !image.id ? 'Please Select an image' : null
                  }
                />
                <ImageSelect
                  initialImage={image.mediaItem_fr}
                  label="Image (French)"
                  name="headerImage"
                  type="Thumbnail"
                  handleSelect={(value, name) =>
                    handleImageChange(value, index, 'id_fr')
                  }
                />
              </Stack>
              <Wysiwyg
                name="text"
                label="Text"
                val={image.text}
                onChange={(content) =>
                  handleImagesChange(content, index, 'text')
                }
              />
              <Wysiwyg
                name="text_fr"
                label="Text (fr)"
                val={image.text_fr}
                onChange={(content) =>
                  handleImagesChange(content, index, 'text_fr')
                }
              />
            </div>
          ))
        : null}
      {showError && images.length !== parseInt(limit) ? (
        <Alert severity="error">Please select {limit} images</Alert>
      ) : null}
    </div>
  );
}
