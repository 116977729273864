import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "../../Inputs/Button";

const PollListItem = ({ poll }) => {
    const {name, createdAt, id} = poll;

  return (
    <li>
      <div className="list-inner">
        <div className="name">{name}</div>
        <div className="date">{createdAt && moment(createdAt).format("MM-DD-YYYY")}</div>
        <div className="button-container">
          <Button
            className="btn-sm"
            text="Edit"
            btnLink
            url={`/dashboard/polls/poll/${id}`}
          />
        </div>
      </div>
    </li>
  );
};

PollListItem.propTypes = {
  poll: PropTypes.object.isRequired,
};

export default PollListItem;
