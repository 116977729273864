import React, { useEffect, useState } from 'react';
import List from './List';
import { isEmpty } from '../../utils';
import Pagination from '../Layout/Pagination';
import axios from 'axios';
import AsyncSelect from '../Inputs/AsyncSelect';
import Select from '../Inputs/Select';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from '../Inputs/DatePicker';
import { Button } from '@mui/material';


export default function AffiliateSummaryDashboard({ history, match }) {
  const [businessOptions, setBusinessOptions] = useState([]);
  const [affiliateProgramOptions, setAffliateProgramOptions] = useState([]);

  useEffect(() => {
    fetchBusinesses();
    fetchAffiliatePrograms();
  }, []);

  const [totalResults, setTotalResults] = useState(0);
  const [transactionData, setTransactionData] = useState([]);
  const [page, setPage] = useState(1);
  const [userFilter, setUserFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortBy, setSortBy] = useState({ field: 'label', asc: true });
  const [business, setBusiness] = useState([]);
  const [affiliateProgram, setAffiliateProgram] = useState([]);
  const [activityType, setActivityType] = useState([]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const { params } = match;

  const renderPagination = () => {
    if (
      (!isEmpty(errors) && errors.message === 'No data found') ||
      !totalResults
    ) {
      return null;
    } else
      return (
        <Pagination
          totalRecords={totalResults}
          page={params.page ? params.page : '1'}
          onPageChange={changePage}
        />
      );
  };

  const changePage = (page) => {
    fetchData(page);
    setPage(page);
    history.push(`/dashboard/affiliate-business-summary/${page}`);
  };

  const fetchAffiliatePrograms = async () => {
    try {
      const res = await axios.get('/api/affiliates');

      setAffliateProgramOptions(
        res.data.map((el) => ({
          label: el.name,
          value: el.id,
        }))
      );
    } catch (err) {
      setErrors(err.response.data);
    }
  };

  const fetchBusinesses = async () => {
    axios
      .get('/api/businesses/dropdown')
      .then((res) => {
        setBusinessOptions(
          res.data
        );
      })
      .catch((err) => {
        setErrors(err.response.data);
      });
  };

  const fetchUsers = async (inputValue) => {
    const url = '/api/users';
    const options = {
      dropdown: true,
      search: inputValue,
    };

    try {
      const res = await axios.get(url, { params: options });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const setSort = (sort) => {
    setSortBy({ field: sort, asc: !sortBy.asc });
  };

  useEffect(() => {
    fetchData();
    setPage(1);
  }, [
    userFilter,
    startDate,
    endDate,
    JSON.stringify(sortBy),
    JSON.stringify(business),
    JSON.stringify(affiliateProgram),
    JSON.stringify(activityType),
  ]);

  const fetchData = async (page = 1, csv = false) => {
    const url = '/api/reports/affiliateBusinessSummary';
    const options = {
      page: page,
      csv: csv,
      startDate: moment(startDate).startOf('day').toISOString(),
      endDate: moment(endDate).endOf('day').toISOString(),
      business: business && business.map((el) => el.value).toString(),
      program:
        affiliateProgram && affiliateProgram.map((el) => el.value === null ? "null" : el.value).toString(),
      activity: activityType && activityType.map((el) => el.value).toString(),
      order: `${sortBy.field},${sortBy.asc ? 'ASC' : 'DESC'}`,
    };
    if (page === 1) history.push(`/dashboard/affiliate-business-summary/1`);
    setErrors({});
    setLoading(true);
    try {
      const res = await axios.get(url, { params: options });

      if (csv) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `affiliate-summary-export-${moment().format('MM-DD-YYYY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
      } else {
        setTransactionData(res.data.rows);
        setTotalResults(res.data.count);
      }
      setLoading(false);
    } catch (error) {
      setErrors(error.response.data);
      setLoading(false);
      setTotalResults(0);
    }
  };

  const activityOptions = [
    { label: 'Sale', value: 'affiliate_sale' },
    { label: 'Lead', value: 'affiliate_lead' },
    { label: 'Click', value: 'affiliate_click' },
    { label: 'Bonus', value: 'affiliate_bonus' },
  ];

  const columns = [
    { label: 'Business Name', name: 'name' },
    { label: 'Program', name: 'program' },
    { label: 'Total affiliate spend', name: 'totalSpend' },
    { label: 'Total commission', name: 'totalComission' },
    { label: 'User commission share', name: 'userCommision' },
    { label: 'Upp Commission Share', name: 'uppCommision' },
    { label: 'Number of Affiliate Leads', name: 'leads' },
    { label: 'Number of UPP Leads', name: 'uppLeadCount' },
    { label: 'Number of purchases', name: 'sales' },
    { label: 'Number of page hits', name: 'pageHits' },
    { label: 'Cash Back %', name: 'cashBackPercentage' },
  ];

  return (
    <div>
      <h1>Affiliate Business Dashboard</h1>

      <Controls>
        <Select
          value={business}
          name="Business"
          label="Business"
          options={businessOptions}
          onChange={(v) => setBusiness(v)}
          isMulti
          className="input-lg"
        />
        <Select
          value={affiliateProgram}
          name="affiliateProgram"
          label="Affiliate Program"
          placeholder="Affiliate Program"
          options={[{ value: null, label: 'None' }, ...affiliateProgramOptions]}
          className="input-lg"
          onChange={(v) => setAffiliateProgram(v)}
          isMulti
          error={errors.affiliateProgramId}
        />
        <DatePicker
          name="startDate"
          selected={startDate}
          label="From Date"
          onChange={(date) => setStartDate(date)}
          maxDate={endDate}
          isClearable={true}
          placeholder="Start Date"
        />
        <DatePicker
          name="endDate"
          selected={endDate}
          label="To Date"
          onChange={(date) => setEndDate(date)}
          minDate={startDate}
          isClearable={true}
          placeholder="End Date"
        />
        <Select
          value={activityType}
          name="activityType"
          label="Activity Type"
          placeholder="Activity Type"
          options={activityOptions}
          className="input-lg"
          onChange={(v) => setActivityType(v)}
          isMulti
          error={errors.activityType}
        />
      </Controls>
      <Button onClick={() => fetchData(1, true)}>Export</Button>
      <List
        columns={columns}
        setSort={setSort}
        sortBy={sortBy}
        data={transactionData}
        loading={loading}
        errors={errors}
      />
      {renderPagination()}
    </div>
  );
}

const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;

  .input-cntr {
    margin: 10px;
    width: 15%;
  }
`;
