// NO TOKEN
// Ask for email address
// Send the email address to the api
// Tell users to check their email

// IF TOKEN
// Ask for a new password
// Send new password + token to the backend
// IF 200 redirect to login

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Redirect } from "react-router-dom";
import TextField from "../Inputs/TextField";
import Button from "../Inputs/Button";
import { isEmpty } from "lodash";

export class PasswordReset extends Component {
  constructor(props) {
    super(props);
    const {
      location: { search },
    } = this.props;
    const query = new URLSearchParams(search);
    this.token = query.get('token');

    this.state = {
      email: "",
      password: "",
      password2: "",
      errors: null,
      emailSent: false,
      requestLoading: false,
      resetSuccess: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  requestReset = () => {
    this.setState({ requestLoading: true });
    const url = "/api/users/forgotPassword";
    const { email } = this.state;
    const payload = { email };
    axios
      .post(url, payload)
      .then(() => {
        this.setState({ emailSent: true, requestLoading: false });
      })
      .catch((err) => {
        this.setState({
          errors: err.response.data,
          requestLoading: false,
          emailSent: false,
        });
      });
  };

  resetPassword = () => {
    this.setState({ requestLoading: true });
    const { password, password2 } = this.state;
    const url = `/api/users/resetPassword/${this.token}`;
    const payload = { password, password2 };
    axios
      .post(url, payload)
      .then(() => {
        this.setState({ requestLoading: false, resetSuccess: true });
      })
      .catch((err) => {
        this.setState({ errors: err.response.data, requestLoading: false });
      });
  };

  render() {
    const { loading, isAuthenticated } = this.props;
    const {
      email,
      password,
      password2,
      errors,
      requestLoading,
      resetSuccess,
      emailSent,
    } = this.state;
    if (!loading && isAuthenticated) return <Redirect to="/dashboard" />;
    if (resetSuccess)
      return (
        <div className="home">
          <h2>Your password has been reset. Go ahead and log in.</h2>
        </div>
      );

    if (this.token)
      return (
        <div className="home">
          <h1>Enter your new password</h1>
          <TextField
            name="password"
            label="Password"
            value={password}
            onChange={this.onChange}
            required
            type="password"
            error={!isEmpty(errors) ? errors.password : null}
          />
          <TextField
            name="password2"
            label="Confirm password"
            value={password2}
            onChange={this.onChange}
            required
            type="password"
            error={password !== password2 ? "Passwords don't match" : null}
          />
          <Button
            text="Reset password"
            isLoading={requestLoading}
            onClick={this.resetPassword}
            disabled={password !== password2}
            className="test test1 test2323"
          />
        </div>
      );

    return (
      <div className="home">
        <h1>Reset your password</h1>

        <TextField
          name="email"
          label="Email address"
          value={email}
          onChange={this.onChange}
          required
          error={errors}
          helperText="We need your email address so we can reset your password."
        />
        <Button
          text="Send"
          isLoading={requestLoading}
          onClick={this.requestReset}
          disabled={emailSent}
        />
        {emailSent ? <p>Check your email for a password reset link</p> : null}
      </div>
    );
  }
}

PasswordReset.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
