import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

const ListItem = ({ data, columns, columnWidth, showInvalid }) => {
  const renderData = () => {
    if (columns) {
      return columns.map((el) => {
        let text = data[el.name] && `${data[el.name]}`;

        if (typeof data[el.name] === 'object' && !el.type)
          text = data[el.name]?.label;

        if (el.type === 'datetime')
          text = data[el.name]
            ? `${moment(data[el.name]).format('YYYY-MM-DD')} at 
          ${moment(data[el.name]).format('HH:mm')}`
            : '';

        if (el.type === 'date')
          text = data[el.name]
            ? `${moment(data[el.name]).format('YYYY-MM-DD')}`
            : '';

        if (el.type === 'username')
          text =
            data[el.name] &&
            `${data[el.name]?.firstName} ${data[el.name]?.lastName} (${
              data[el.name]?.email
            })`;

        return <div title={text}>{text}</div>;
      });
    }
  };

  return (
    <StyledListItem unhealthy={showInvalid && !data.healthy} columnWidth={columnWidth}>{renderData()}</StyledListItem>
  );
};

ListItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ListItem;

const StyledListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p) => (p.unhealthy ? 'rgba(255,20,20,0.3)' : '#fff')};
  div {
    width: ${(p) => (p.columnWidth ? p.columnWidth : '12.5%')};
    word-break: break-word;
    line-height: 150%;
    padding: 5px;

    word-break: normal;
    overflow-y: auto;

    ::-webkit-scrollbar {
      height: 7px; /* height of horizontal scrollbar ← You're missing this */
      width: 4px; /* width of vertical scrollbar */
    }
    ::-webkit-scrollbar-thumb {
      background: #cfcfcf;
      border-radius: 10px;
    }
  }
`;
