import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import TextField from "../../Inputs/TextField";
import Select from "../../Inputs/Select";
import Button from "../../Inputs/Button";

const TagSearch = ({ search, setSearch, fetchTags }) => {
  return (
    <div className="flex">
      <TextField
        name="name"
        value={search}
        placeholder="Search"
        className="input-sm"
        onChange={(e) => setSearch(e.target.value)}
        maxLength={30}
      />
      <Button text="Search" onClick={fetchTags} />
    </div>
  );
};

TagSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
};

export default TagSearch;
