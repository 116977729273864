import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from '../../../utils';
import Button from '../../Inputs/Button';
import DatePicker from '../../Inputs/DatePicker';
import Select from '../../Inputs/Select';
import Spinner from '../../Inputs/Spinner';
import TextField from '../../Inputs/TextField';
import { AdjustUserCashbackBalance } from './AdjustUserCashbackBalance';
import {
  albertaOptions,
  annualIncomeOptions,
  bcOptions,
  countryOptions,
  employmentOptions,
  genderOptions,
  leaseOptions,
  manitobaOptions,
  newBrunswickOptions,
  NewfoundlandAndLabradorOptions,
  NovaScotiaOptions,
  ontarioOptions,
  PrinceEdwardIslandOptions,
  provinceOptions,
  QuebecOptions,
  rentOptions,
  SaskatchewanOptions,
  unionRoleOptions,
  vehicleMakeOptions,
} from './selectOptions';
export class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      birthdate: null,
      gender: '',
      numberOfDependents: '',
      local: '',
      howDidYouFindUs: '',
      referrer: '',
      role: {},
      id: '',
      title: '',
      roleOptions: [],
      isActive: false,
      employmentStatus: {},
      unionRole: {},
      mortgageRenewalDate: null,
      homeOwnership: {},
      vehicleInsuranceCompany: '',
      vehicleLeaseExpirationDate: null,
      leaseOrFinance: '',
      vehicleMake: {},
      wirelessCarrier: {},
      homeInsuranceCompany: {},
      unionOptions: [],
      union: {},
      address: '',
      loading: true,
      password: '',
      annualIncome: {},
      streetAddress: '',
      appartment: '',
      city: '',
      postalCode: '',
      country: {},
      province: {},
      errors: {},
      referralCode: '',
      unionStreetAddress: '',
      unionAppartment: '',
      unionCountry: {},
      unionCity: '',
      unionProvince: {},
      unionPostalCode: '',
      wirelessCarrierOptions: [],
      insuranceCompanyOptions: [],
      autoInsuranceOptions: [],
      cashback: '',
      adjustUserCashbackBalance: false,
    };
    this.userRolesPriorityOrder = [
      'SUPER',
      'WEBSITE ADMIN',
      'USER ADMIN',
      'CONTENT EDITOR',
      'USER',
    ];
  }

  isRoleAvailable(currentUserRole, targetRole) {
    const currentRoleIndex =
      this.userRolesPriorityOrder.indexOf(currentUserRole);
    if (currentRoleIndex === -1) {
      return false;
    }
    const targetRoleIndex = this.userRolesPriorityOrder.indexOf(targetRole);
    if (targetRoleIndex === -1) {
      return false;
    }

    return targetRoleIndex >= currentRoleIndex;
  }

  async componentDidMount() {
    const { match } = this.props;
    if (match.url.includes('add')) {
      this.setState({ loading: false });
    }

    try {
      const res = await axios.get('/api/users/roles');
      const arr = res.data
        .map((el) => {
          return { label: el.roleName, value: el.id };
        })
        // .filter((role) =>
        //   this.isRoleAvailable(this.props.auth.user.role, role.label)
        // );
      this.setState({ roleOptions: arr });
    } catch (error) {
      this.setState({ errors: error.response.data });
    }
    if (match.params.id) {
      try {
        const res = await axios.get(`/api/users/${match.params.id}`);
        this.updateState(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    try {
      const res = await axios.get('/api/unions', {
        params: { dropdown: true },
      });
      this.setState({
        unionOptions: res.data.map((union) => {
          return { label: union.name, value: union.id };
        }),
      });
    } catch (error) {
      this.setState({ errors: error.response.data });
    }

    try {
      const res = await axios.get('/api/carriers', {
        params: { dropdown: true },
      });
      this.setState({
        wirelessCarrierOptions: res.data.map((el) => {
          return { label: el.name, value: el.id };
        }),
      });
    } catch (error) {
      this.setState({ errors: error.response.data });
    }

    try {
      const res = await axios.get('/api/homeinsurances', {
        params: { dropdown: true },
      });
      this.setState({
        insuranceCompanyOptions: res.data.map((el) => {
          return { label: el.name, value: el.id };
        }),
      });
    } catch (error) {
      this.setState({ errors: error.response.data });
    }

    try {
      const res = await axios.get('/api/autoinsurances', {
        params: { dropdown: true },
      });
      this.setState({
        autoInsuranceOptions: res.data.map((el) => {
          return { label: el.name, value: el.id };
        }),
      });
    } catch (error) {
      this.setState({ errors: error.response.data });
    }
  }

  updateState = (data) => {
    const { roleOptions } = this.state;
    const createObjectFromString = (data) => {
      if (data) {
        return { label: data, value: data };
      } else return null;
    };
    this.setState({
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      birthdate: data.birthdate && moment(data.birthdate).toDate(),
      mortgageRenewalDate:
        data.mortgageRenewalDate && moment(data.mortgageRenewalDate).toDate(),
      vehicleLeaseExpirationDate:
        data.vehicleLeaseExpirationDate &&
        moment(data.vehicleLeaseExpirationDate).toDate(),
      email: data.email,
      address: data.address,
      isActive: data.isActive,
      gender: createObjectFromString(data.gender),
      union: !isEmpty(data.union)
        ? { label: data.union.name, value: data.union.id }
        : null,
      local: data.local,
      howDidYouFindUs: data.howDidYouFindUs,
      role: roleOptions.find((roleOption) => roleOption.label === data.role),
      title: data.title,
      emailConfirmed: data.emailConfirmed,
      numberOfDependents: data.numberOfDependents,
      employmentStatus: createObjectFromString(data.employmentStatus),
      referrer: data.referrerName,
      unionRole: createObjectFromString(data.unionRole),
      homeOwnership: createObjectFromString(data.homeOwnership),

      leaseOrFinance: createObjectFromString(data.vehicleOwnership),
      vehicleMake: createObjectFromString(data.makeOfVehicle),
      wirelessCarrier: !isEmpty(data.wirelessCarrier)
        ? {
            label: data.wirelessCarrier.name,
            value: data.wirelessCarrier.id,
          }
        : null,
      homeInsuranceCompany: !isEmpty(data.homeInsurance)
        ? {
            label: data.homeInsurance.name,
            value: data.homeInsurance.id,
          }
        : null,
      vehicleInsuranceCompany: !isEmpty(data.autoInsurance)
        ? {
            label: data.autoInsurance.name,
            value: data.autoInsurance.id,
          }
        : null,
      annualIncome: createObjectFromString(data.annualIncome),
      streetAddress: data.streetAddress,
      postalCode: data.postalCode,
      appartment: data.appartment,
      unionStreetAddress: data.unionStreetAddress,
      unionAppartment: data.unionAppartment,
      unionPostalCode: data.unionPostalCode,
      unionCity: { value: data.unionCity, label: data.unionCity },
      unionCountry: createObjectFromString(data.unionCountry),
      unionProvince: createObjectFromString(data.unionProvince),
      country: createObjectFromString(data.country),
      province: createObjectFromString(data.province),
      city: { value: data.city, label: data.city },
      referralCode: data.referralCode,
      cashback: data.cashback,
      loading: false,
    });
  };

  // Reset state when component switches to ADD
  componentDidUpdate(prevProps) {
    if (prevProps.match.url !== this.props.match.url) {
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        birthdate: null,
        gender: '',
        numberOfDependents: '',
        local: '',
        howDidYouFindUs: '',
        referrer: '',
        role: {},
        id: '',
        title: '',
        roleOptions: [],
        isActive: false,
        employmentStatus: {},
        unionRole: {},
        mortgageRenewalDate: null,
        homeOwnership: {},
        vehicleInsuranceCompany: '',
        vehicleLeaseExpirationDate: null,
        leaseOrFinance: '',
        vehicleMake: {},
        wirelessCarrier: {},
        homeInsuranceCompany: {},
        unionOptions: [],
        union: {},
        address: '',
        password: '',
        annualIncome: {},
        streetAddress: '',
        appartment: '',
        city: '',
        postalCode: '',
        country: {},
        province: {},
        errors: {},
        unionStreetAddress: '',
        unionAppartment: '',
        unionCountry: {},
        unionCity: '',
        unionProvince: {},
        unionPostalCode: '',
        wirelessCarrierOptions: [],
        insuranceCompanyOptions: [],
        autoInsuranceOptions: [],
        loading: !this.props.match.url.includes('add'),
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDate = (date) => {
    this.setState({ birthdate: date });
  };

  handleChangeRole = (role) => {
    this.setState({ role });
  };

  handleSelectEmploymentStatus = (val) => {
    this.setState({ employmentStatus: val });
  };

  handleSelectUnionRole = (val) => {
    this.setState({ unionRole: val });
  };

  handleSelectwirelessCarrier = (val) => {
    this.setState({ wirelessCarrier: val });
  };

  handleSelectleaveOrFinance = (val) => {
    this.setState({ leaseOrFinance: val });
  };

  handleLeaseUp = (val) => {
    this.setState({ vehicleLeaseExpirationDate: val });
  };

  handlevehicleInsuranceCompany = (val) => {
    this.setState({ vehicleInsuranceCompany: val });
  };

  handleSelectVehicleMake = (val) => {
    this.setState({ vehicleMake: val });
  };

  handleSelectannualIncome = (val) => {
    this.setState({ annualIncome: val });
  };

  handlehomeOwnership = (val) => {
    this.setState({ homeOwnership: val });
  };

  handlemortgageRenewal = (val) => {
    this.setState({ mortgageRenewalDate: val });
  };

  handlehomeInsurance = (val) => {
    this.setState({ homeInsuranceCompany: val });
  };

  handleChangeUnion = (val) => {
    this.setState({ union: val });
  };
  handleChangeGender = (val) => {
    this.setState({ gender: val });
  };
  handleChangeCountry = (val) => {
    this.setState({ country: val });
  };
  handleChangeProvince = (val) => {
    this.setState({ province: val });
  };
  handleChangeCountryUnion = (val) => {
    this.setState({ unionCountry: val });
  };
  handleChangeProvinceUnion = (val) => {
    this.setState({ unionProvince: val });
  };
  handleSelectCity = (val) => {
    this.setState({ city: val });
  };
  handleSelectUnionCity = (val) => {
    this.setState({ unionCity: val });
  };

  handleAdjustUserCashbackBalance = () => {
    this.setState({ adjustUserCashbackBalance: true });
  };

  adjustUserCashbackBalanceFinishHandler = () => {
    this.setState({ adjustUserCashbackBalance: false });
  };

  handleSubmit = () => {
    const { match } = this.props;
    this.setState({ loading: true });
    const {
      firstName,
      lastName,
      birthdate,
      email,
      gender,
      numberOfDependents,
      union,
      local,
      role,
      id,
      title,
      isActive,
      employmentStatus,
      unionRole,
      homeInsuranceCompany,
      mortgageRenewalDate,
      homeOwnership,
      vehicleInsuranceCompany,
      vehicleLeaseExpirationDate,
      leaseOrFinance,
      vehicleMake,
      wirelessCarrier,
      annualIncome,
      password,
      streetAddress,
      appartment,
      city,
      postalCode,
      country,
      province,
      unionStreetAddress,
      unionAppartment,
      unionCountry,
      unionCity,
      unionProvince,
      unionPostalCode,
    } = this.state;

    const payload = {
      firstName,
      lastName,
      birthdate,
      local,
      title: title ? title : undefined,
      isActive,
      gender: !isEmpty(gender) ? gender.value : undefined,
      unionId: !isEmpty(union) ? union.value : undefined,
      email,
      numberOfDependents,
      roleId: role?.value,
      annualIncome: annualIncome ? annualIncome.value : undefined,
      employmentStatus: !isEmpty(employmentStatus)
        ? employmentStatus.value
        : undefined,
      unionRole: unionRole ? unionRole.value : undefined,
      makeOfVehicle: vehicleMake ? vehicleMake.value : undefined,
      vehicleLeaseExpirationDate: vehicleLeaseExpirationDate,
      homeOwnership: homeOwnership ? homeOwnership.value : undefined,
      mortgageRenewalDate: mortgageRenewalDate,
      wirelessCarrierId: wirelessCarrier ? wirelessCarrier.value : undefined,
      homeInsuranceId: homeInsuranceCompany
        ? homeInsuranceCompany.value
        : undefined,
      autoInsuranceId: vehicleInsuranceCompany
        ? vehicleInsuranceCompany.value
        : undefined,
      vehicleOwnership: leaseOrFinance ? leaseOrFinance.value : undefined,
      streetAddress,
      appartment,
      city: !isEmpty(city) ? city.value : undefined,
      postalCode,
      country: !isEmpty(country) ? country.value : undefined,
      province: !isEmpty(province) ? province.value : undefined,
      unionStreetAddress,
      unionAppartment,
      unionPostalCode,
      unionCity: !isEmpty(unionCity) ? unionCity.value : undefined,
      unionProvince: !isEmpty(unionProvince) ? unionProvince.value : undefined,
      unionCountry: !isEmpty(unionCountry) ? unionCountry.value : undefined,
      password: password,
    };
    let method = 'post';
    if (match.params.id) {
      method = 'put';
      delete payload.password;
    }
    axios[method](`/api/users/${id}`, payload)
      .then(() => {
        this.setState({ loading: false });
        window.location.replace('/dashboard/users');
      })
      .catch((err) => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  toggleIsActive = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  render() {
    const allowedRoles = ['SUPER', 'WEBSITE ADMIN'];
    const {
      firstName,
      lastName,
      birthdate,
      email,
      gender,
      numberOfDependents,
      union,
      local,
      role,
      id,
      roleOptions,
      isActive,
      loading,
      employmentStatus,
      unionRole,
      homeInsuranceCompany,
      mortgageRenewalDate,
      homeOwnership,
      vehicleInsuranceCompany,
      vehicleLeaseExpirationDate,
      leaseOrFinance,
      vehicleMake,
      wirelessCarrier,
      unionOptions,
      password,
      annualIncome,
      errors,
      streetAddress,
      appartment,
      city,
      country,
      province,
      postalCode,
      unionStreetAddress,
      unionAppartment,
      unionCountry,
      unionCity,
      unionProvince,
      unionPostalCode,
      wirelessCarrierOptions,
      autoInsuranceOptions,
      referralCode,
      insuranceCompanyOptions,
    } = this.state;
    const { match, auth } = this.props;
    if (loading) return <Spinner />;

    let cityOptions = [];
    if (province) {
      switch (province.value) {
        case 'Alberta':
          cityOptions = albertaOptions;
          break;
        case 'British Columbia':
          cityOptions = bcOptions;
          break;
        case 'Manitoba':
          cityOptions = manitobaOptions;
          break;
        case 'New Brunswick':
          cityOptions = newBrunswickOptions;
          break;
        case 'Newfoundland and Labrador':
          cityOptions = NewfoundlandAndLabradorOptions;
          break;
        case 'Nova Scotia':
          cityOptions = NovaScotiaOptions;
          break;
        case 'Ontario':
          cityOptions = ontarioOptions;
          break;
        case 'Prince Edward Island':
          cityOptions = PrinceEdwardIslandOptions;
          break;
        case 'Quebec':
          cityOptions = QuebecOptions;
          break;
        case 'Saskatchewan':
          cityOptions = SaskatchewanOptions;
          break;
      }
    }

    const mailToString = `mailto:?subject=UPP%20referral&body=${process.env.REACT_APP_WEB_URL}/signup?referrerId=${referralCode}`;

    const share = () => {
      if (navigator.share) {
        navigator
          .share({
            title: 'UPP referral',
            text: `Referral Code: ${referralCode}`,
            url: `${process.env.REACT_APP_WEB_URL}/signup?referrerId=${referralCode}`,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Web Share API is not supported in your browser.');
      }
    };

    const handleCopyToClipboard = () => {
      navigator.clipboard.writeText(referralCode);
      alert('Referral Code copied to clipboard.');
    };

    return (
      <div>
        <h1>{match.params.id ? 'Edit user profile' : 'Add a new user'}</h1>

        {isActive ? (
          <p
            onClick={this.toggleIsActive}
            className="toggleIsActive"
            style={{ color: 'red' }}
          >
            Disable user
          </p>
        ) : (
          <p
            onClick={this.toggleIsActive}
            className="toggleIsActive"
            style={{ color: 'blue' }}
          >
            Enable user
          </p>
        )}
        {match.params.id ? (
          navigator.share ? (
            <>
              <p>
                USER ID:
                {id}
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '2vw',
                }}
              >
                <div
                  className="referralCode"
                  onClick={() => {
                    handleCopyToClipboard();
                  }}
                >
                  <p>REFERRAL CODE : {referralCode}</p>
                </div>
                <button className="shareButton" onClick={share}>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3333 12.0161C12.6578 12.0161 12.0533 12.2721 11.5911 12.6732L5.25333 9.13153C5.29778 8.93524 5.33333 8.73896 5.33333 8.53414C5.33333 8.32932 5.29778 8.13303 5.25333 7.93675L11.52 4.42922C12 4.85592 12.6311 5.12048 13.3333 5.12048C14.8089 5.12048 16 3.97691 16 2.56024C16 1.14357 14.8089 0 13.3333 0C11.8578 0 10.6667 1.14357 10.6667 2.56024C10.6667 2.76506 10.7022 2.96135 10.7467 3.15763L4.48 6.66516C4 6.23845 3.36889 5.9739 2.66667 5.9739C1.19111 5.9739 0 7.11747 0 8.53414C0 9.9508 1.19111 11.0944 2.66667 11.0944C3.36889 11.0944 4 10.8298 4.48 10.4031L10.8089 13.9533C10.7644 14.1325 10.7378 14.3203 10.7378 14.508C10.7378 15.882 11.9022 17 13.3333 17C14.7644 17 15.9289 15.882 15.9289 14.508C15.9289 13.134 14.7644 12.0161 13.3333 12.0161Z"
                      fill="#ED1C24"
                    />
                  </svg>
                  <div className="shareText">SHARE</div>
                </button>
              </div>
            </>
          ) : (
            <>
              <p>
                USER ID:
                {id}
              </p>
              <div
                className="referralCode"
                onClick={() => {
                  navigator.clipboard.writeText(referralCode);
                }}
              >
                <p>REFERRAL CODE : {referralCode}</p>
              </div>
              <a href={mailToString}>SHARE</a>
            </>
          )
        ) : null}
        <div style={{ width: '600px' }}>
          <TextField
            name="firstName"
            value={firstName}
            label="First name"
            onChange={this.handleChange}
            error={errors.firstName}
            disabled={!isActive}
          />
          <TextField
            name="lastName"
            value={lastName}
            label="Last name"
            onChange={this.handleChange}
            error={errors.lastName}
            disabled={!isActive}
          />
          <TextField
            name="email"
            value={email}
            label="Email address"
            onChange={this.handleChange}
            error={errors.email}
            disabled={!isActive}
          />
          <DatePicker
            name="birthDate"
            selected={birthdate}
            label="Date of birth"
            onChange={this.handleDate}
            error={errors.birthdate}
            disabled={!isActive}
          />

          <Select
            name="gender"
            label="Gender"
            value={gender}
            onChange={this.handleChangeGender}
            options={genderOptions}
            clearable={false}
            error={errors.gender}
            disabled={!isActive}
          />
          <Select
            name="union"
            label="Union"
            value={union}
            onChange={this.handleChangeUnion}
            options={unionOptions}
            clearable={false}
            error={errors.unionId}
            disabled={!isActive}
          />

          <TextField
            name="local"
            value={local}
            label="Local"
            onChange={this.handleChange}
            error={errors.local}
            disabled={!isActive}
          />

          <TextField
            name="numberOfDependents"
            value={numberOfDependents}
            label="Number of Dependents"
            onChange={this.handleChange}
            error={errors.numberOfDependents}
            disabled={!isActive}
          />

          {match.params.id === auth.user.id ? null : (
            <Select
              name="role"
              label="Role"
              value={role}
              onChange={this.handleChangeRole}
              options={roleOptions}
              clearable={false}
              error={errors.roleId}
              disabled={!isActive || this.props.auth.user.role !== "SUPER"}
            />
          )}
        </div>

        <Select
          value={annualIncome}
          name="annualIncome"
          label="Annual Income"
          options={annualIncomeOptions}
          onChange={this.handleSelectannualIncome}
          error={errors.annualIncome}
        />

        <Select
          value={employmentStatus}
          name="employmentStatus"
          label="Employment Status"
          options={employmentOptions}
          onChange={this.handleSelectEmploymentStatus}
          error={errors.categoryId}
        />

        <Select
          value={unionRole}
          name="unionRole"
          label="Union Role"
          options={unionRoleOptions}
          onChange={this.handleSelectUnionRole}
          error={errors.unionRole}
        />

        <Select
          value={wirelessCarrier}
          name="wirelessCarrier"
          label="Wireless Carrier"
          options={wirelessCarrierOptions}
          onChange={this.handleSelectwirelessCarrier}
          error={errors.wirelessCarrier}
        />

        <Select
          value={vehicleMake}
          name="vehicleMake"
          label="Make of Vehicle"
          options={vehicleMakeOptions}
          onChange={this.handleSelectVehicleMake}
          error={errors.vehicleMake}
        />

        <Select
          value={leaseOrFinance}
          name="leaseOrFinance"
          label="Do you lease or finance your vehicle?"
          options={leaseOptions}
          onChange={this.handleSelectleaveOrFinance}
          error={errors.leaseOrFinance}
        />

        <DatePicker
          name="vehicleLeaseExpirationDate"
          selected={vehicleLeaseExpirationDate}
          label="When is your lease up?"
          onChange={this.handleLeaseUp}
          error={errors.vehicleLeaseExpirationDate}
        />

        <Select
          value={vehicleInsuranceCompany}
          name="vehicleInsuranceCompany"
          label="What company do you use for your vehicle's insurance?"
          options={autoInsuranceOptions}
          onChange={this.handlevehicleInsuranceCompany}
          error={errors.vehicleInsuranceCompany}
        />

        <Select
          value={homeOwnership}
          name="homeOwnership"
          label="Do you rent or own your home?"
          options={rentOptions}
          onChange={this.handlehomeOwnership}
          error={errors.homeOwnership}
        />

        <DatePicker
          selected={mortgageRenewalDate}
          name="mortgageRenewalDate"
          label="When is your mortgage up for renewal?"
          onChange={this.handlemortgageRenewal}
          error={errors.mortgageRenewalDate}
        />

        <Select
          value={homeInsuranceCompany}
          name="homeInsuranceCompany"
          label="What company do you use for your home, condo or renter’s insurance?"
          options={insuranceCompanyOptions}
          onChange={this.handlehomeInsurance}
          error={errors.homeInsuranceCompany}
        />

        {/* home address */}
        <TextField
          name="streetAddress"
          value={streetAddress}
          label="Home Street Address"
          onChange={this.handleChange}
          error={errors.streetAddress}
          disabled={!isActive}
        />
        <TextField
          name="appartment"
          value={appartment}
          label="Home Apartment Number"
          onChange={this.handleChange}
          error={errors.appartment}
          disabled={!isActive}
        />
        <Select
          value={province}
          name="province"
          label="Province"
          options={provinceOptions}
          onChange={this.handleChangeProvince}
          error={errors.province}
        />
        <Select
          name="city"
          value={city}
          label="City"
          options={cityOptions.map((el) => ({
            label: el.en,
            value: el.en,
          }))}
          onChange={this.handleSelectCity}
          error={errors.city}
          disabled={!isActive}
        />
        <TextField
          name="postalCode"
          value={postalCode}
          label="Postal Code"
          onChange={this.handleChange}
          error={errors.postalCode}
          disabled={!isActive}
        />

        <Select
          value={country}
          name="country"
          label="Country"
          options={countryOptions}
          onChange={this.handleChangeCountry}
          error={errors.country}
        />

        {/* home address */}
        <TextField
          name="unionStreetAddress"
          value={unionStreetAddress}
          label="Union Street Address"
          onChange={this.handleChange}
          error={errors.streetAddress}
          disabled={!isActive}
        />
        <TextField
          name="unionAppartment"
          value={unionAppartment}
          label="Union Apartment Number"
          onChange={this.handleChange}
          error={errors.appartment}
          disabled={!isActive}
        />
        <Select
          value={unionProvince}
          name="unionProvince"
          label="Union Province"
          options={provinceOptions}
          onChange={this.handleChangeProvinceUnion}
          error={errors.province}
        />
        <Select
          name="unionCity"
          value={unionCity}
          label="Union City"
          options={cityOptions.map((el) => ({
            label: el.en,
            value: el.en,
          }))}
          onChange={this.handleSelectUnionCity}
          error={errors.unionCity}
          disabled={!isActive}
        />
        <TextField
          name="unionPostalCode"
          value={unionPostalCode}
          label="Union Postal Code"
          onChange={this.handleChange}
          error={errors.postalCode}
          disabled={!isActive}
        />

        <Select
          value={unionCountry}
          name="unionCountry"
          label="Union Country"
          options={countryOptions}
          onChange={this.handleChangeCountryUnion}
          error={errors.country}
        />

        {match.params.id ? null : (
          <TextField
            name="password"
            value={password}
            label="Password"
            onChange={this.handleChange}
            error={errors.password}
            disabled={!isActive}
          />
        )}

        {this.state.id && allowedRoles.includes(this.props.auth.user.role) && (
          <div className="input-cntr">
            <Button
              text="Adjust Cashback Balance"
              onClick={this.handleAdjustUserCashbackBalance}
            />
          </div>
        )}

        {loading ? (
          <Spinner />
        ) : (
          <Button text="Save changes" onClick={this.handleSubmit} />
        )}

        {this.state.adjustUserCashbackBalance && (
          <AdjustUserCashbackBalance
            open={this.state.adjustUserCashbackBalance}
            closeHandler={this.adjustUserCashbackBalanceFinishHandler}
            userId={this.state.id}
          />
        )}
      </div>
    );
  }
}

EditUser.propTypes = {
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
