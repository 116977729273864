import axios from 'axios';
import { GET_ERRORS, GET_BUSINESS } from '../types';

export const getBusinesses = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/public/businesses', { params: { dropdown: true } });
    dispatch({
      type: GET_BUSINESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
