/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FroalaEditor from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
// Plugins - Images and Files
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/css/plugins/image_manager.min.css';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/css/plugins/file.min.css';
// Plugins - Fonts, Text
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/css/plugins/table.min.css';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/css/plugins/emoticons.min.css';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/css/plugins/special_characters.min.css';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
// Plugins - Editor window
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/css/plugins/fullscreen.min.css';

import { isEmpty } from '../../../utils';

const Wysiwyg = ({
  autoFocus,
  charCounterMax,
  name,
  label,
  val,
  onChange,
  error,
  toolbarInline,
  pollOptions,
}) => {
  // Refactor to not use IDs for styling buttons, this is a temporary fix
  Froalaeditor.DefineIcon('insert', { NAME: 'plus', SVG_KEY: 'add' });
  Froalaeditor.RegisterCommand('primary-btn', {
    title: 'Insert Primary Button',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      this.html.insert("<a id='wysiwyg-primary-btn' href='#'>Click me</a>");
    },
  });
  Froalaeditor.DefineIcon('insert', { NAME: 'plus', SVG_KEY: 'add' });
  Froalaeditor.RegisterCommand('secondary-btn', {
    title: 'Insert Primary Button',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      this.html.insert("<a id='wysiwyg-secondary-btn' href='#'>Click me</a>");
    },
  });

  let buttons = [];
  if (!isEmpty(pollOptions)) {
    pollOptions.forEach((el) => {
      console.log('Running Register Commands');
      Froalaeditor.DefineIcon('insert', { NAME: 'plus', SVG_KEY: 'add' });
      Froalaeditor.RegisterCommand(`${el.name}`, {
        title: el.name,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
          this.html.insert(
            `<div data-poll='${el.id}' id='custom-form'>form</div>`
          );
        },
      });
    });
    buttons = pollOptions.map((el) => el.name);
  }

  const config = {
    autofocus: autoFocus,
    imageUploadURL: '/api/files/froala',
    imageMaxSize: 1024 * 1024 * 5,
    requestHeaders: {
      ['x-auth-token']: localStorage.token,
    },
    key: '5OA4gG5C3C3F3B3A3D-13vjliixlxe1F-11A-9bzcI1qrhE5B4E4D3F2I3B8A4A4E4==',
    heightMax: 400,
    toolbarSticky: false,
    toolbarInline: toolbarInline,
    toolbarVisibleWithoutSelection: true,
    charCounterCount: true,
    codeBeautifierOptions: {
      end_with_newline: true,
      indent_inner_html: true,
      extra_liners:
        "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
      brace_style: 'expand',
      indent_char: ' ',
      indent_size: 4,
      wrap_line_length: 0,
    },
    // theme: 'gray',
    useClasses: false,
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'textColor',
          'strikeThrough',
          'backgroundColor',
        ],
      },
      moreParagraph: {
        buttons: ['formatOL', 'formatUL'],
      },
      moreRich: {
        buttons: [],
      },
      moreMisc: {
        buttons: ['undo', 'redo', ...buttons],
      },
    },
    pastePlain: true,
    fontFamilySelection: true,
    fontSizeSelection: true,
    paragraphFormatSelection: true,
  };

  if (charCounterMax) {
    config.charCounterMax = charCounterMax;
  }

  return (
    <div className="input-cntr wysiwyg">
      <label className="label" htmlFor={name}>
        {label}
      </label>
      <div style={{ marginTop: label ? '10px' : '0px' }} className="inner">
        <FroalaEditor model={val} onModelChange={onChange} config={config} />
      </div>
      {error ? (
        <small className="invalid-feedback">
          <i className="fas fa-times" />
          {error}
        </small>
      ) : null}
    </div>
  );
};

Wysiwyg.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  val: PropTypes.string.isRequired,
  toolbarInline: PropTypes.bool,
};

Wysiwyg.defaultProps = {
  toolbarInline: false,
};

export default Wysiwyg;
