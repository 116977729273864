import React from 'react';
import { NavLink, Link } from 'react-router-dom';

export default function Menu({ role }) {
  // Items that will appear in the sidebar
  // Don't forget to also add these in App.js Dashboard route
  // Don't forget to also add these in the Dashboard component
  const menuItems = [
    { name: 'Pages', url: 'pages', role: ['CONTENT EDITOR', 'WEBSITE ADMIN'] },
    { name: 'Posts', url: 'posts', role: ['CONTENT EDITOR', 'WEBSITE ADMIN'] },
    {
      name: 'Discounts',
      url: 'discounts',
      role: ['CONTENT EDITOR', 'WEBSITE ADMIN'],
    },
    {
      name: 'Businesses',
      url: 'businesses',
      role: ['CONTENT EDITOR', 'WEBSITE ADMIN'],
    },
    {
      name: 'Categories',
      url: 'categories',
      role: ['CONTENT EDITOR', 'WEBSITE ADMIN'],
    },
    {
      name: 'Manage Users',
      url: 'users',
      role: ['USER ADMIN', 'WEBSITE ADMIN'],
    },
    {
      name: 'Carousel',
      url: 'carousel',
      role: ['CONTENT EDITOR', 'WEBSITE ADMIN'],
    },
    { name: 'Polls', url: 'polls', role: ['CONTENT EDITOR', 'WEBSITE ADMIN'] },
    {
      name: 'Dropdowns',
      url: 'dropdowns',
      role: ['CONTENT EDITOR', 'WEBSITE ADMIN'],
    },
    { name: 'Report', url: 'report', role: ['WEBSITE ADMIN'] },
    {
      name: 'Media Library',
      url: 'media-library',
      role: ['CONTENT EDITOR', 'WEBSITE ADMIN'],
    },
    { name: 'Tags', url: 'tags/en', role: ['CONTENT EDITOR', 'WEBSITE ADMIN'] },
    { name: 'Audit', url: 'audit', role: [] },
    {
      name: 'Transactions',
      url: 'transactions',
      role: ['USER ADMIN', 'WEBSITE ADMIN'],
    },
    {
      name: 'Affiliate Business Summary',
      url: 'affiliate-business-summary',
      role: ['USER ADMIN', 'WEBSITE ADMIN'],
    },
    {
      name: 'User Activity Summary',
      url: 'user-activity-summary',
      role: ['USER ADMIN', 'WEBSITE ADMIN'],
    },
    { name: 'Subscriptions', url: 'local-business-subscriptions', role: [] },
  ];
  return (
    <div className="nav-section">
      <h4 className="heading">Menu</h4>
      <ul>
        <Link to="/dashboard" className="navlink">
          <i className="far fa-sticky-note" />
          Home
        </Link>
        {menuItems.map((el) => {
          if (el.role.includes(role) || role === 'SUPER') {
            return (
              <li key={el.url}>
                <NavLink
                  className="navlink"
                  activeClassName="navlink-active"
                  to={`/dashboard/${el.url}`}
                >
                  <i className="far fa-sticky-note" />
                  {el.name}
                </NavLink>
              </li>
            );
          } else return null;
        })}
      </ul>
    </div>
  );
}
