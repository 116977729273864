import { GET_MEDIA, SET_MEDIA_LOADING } from '../types';

const initialState = {
  media: {},
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MEDIA:
      return {
        media: payload,
        loading: false,
      };
    case SET_MEDIA_LOADING:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
}
