import React from "react";

const PostLIstHeader = () => {
  return (
    <li className="header">
      <div className="list-inner">
        <div className="name">Title</div>
        <div className="date">Start Date</div>
        <div className="date">End Date</div>
        <div className="button-container">Action</div>
      </div>
    </li>
  );
};

export default PostLIstHeader;
