import {
  GET_ALL_DISCOUNTS,
  GET_DISCOUNTS,
  SET_DISCOUNTS_LOADING,
  CLEAR_DISCOUNTS,
  GET_ALL_DISCOUNTS_ERROR,
} from '../types';

const initialState = {
  allDiscounts: [],
  discounts: {},
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_DISCOUNTS_ERROR:
      return {
        ...state,
        allDiscounts: [],
      };
    case GET_ALL_DISCOUNTS:
      return {
        ...state,
        allDiscounts: payload.rows,
        loading: false,
      };
    case GET_DISCOUNTS:
      return {
        ...state,
        discounts: payload,
        loading: false,
      };
    case SET_DISCOUNTS_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case CLEAR_DISCOUNTS:
      return initialState;

    default:
      return state;
  }
}
