// Fetch pages
// Display a page list
// Create new pages
// Delete pages (list)

// stateless?

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPages } from "../../../redux/actions/pagesActions";
import Header from "../../Layout/Header";
import Pagination from "../../Layout/Pagination";
import PageList from "./PageList";
import PageSearch from "./PageSearch";

const Pages = ({ pages, getPages, history, match, errors }) => {
  const { results, totalResults } = pages;
  const [page, setPage] = useState(1);
  const [error, setError] = useState({});

  const [search, setSearch] = useState({
    name: "",
  });

  useEffect(() => {
    getPages();
  }, []);

  const { name, orderType, orderField } = search;

  const changePage = (page) => {
    getPages(page, name);
    setPage(page++);
  };

  const handleSearch = (e, name, order) => {
    e.preventDefault();
    setPage(1);
    getPages(1, name);
    history.push("/dashboard/pages/");
  };

  return (
    <div>
      <Header
        title="Pages"
        btnText="New Page"
        btnLink
        url="/dashboard/pages/add"
      />
      <PageSearch
        handleSearch={handleSearch}
        setSearch={setSearch}
        search={search}
      />
      <PageList pages={results} errors={errors} />
      {totalResults ? (
        <Pagination
          totalRecords={totalResults}
          page={page}
          onPageChange={changePage}
        />
      ) : null}
    </div>
  );
};

Pages.propTypes = {
  pages: PropTypes.object.isRequired,
  getPages: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pages: state.pages.pages,
  errors: state.errors,
});

const mapDispatchToProps = {
  getPages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
