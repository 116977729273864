import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import DropdownList from "./Dropdown/DropdownList";
import Select from "../../Inputs/Select";
import css from './Dropdowns.module.scss';

function Dropdowns({ match, history }) {
  const { tab } = match.params;

  const selectOptions = [
    { value: "unions", label: "Union" },
    { value: "autoinsurances", label: "Auto Insurance" },
    { value: "homeinsurances", label: "Home Insurance" },
    { value: "carriers", label: "Wireless Carrier" },
  ];

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    selectOptions.forEach((el) => {
      if (tab === el.value) {
        setActiveTab({ label: el.label, value: el.value });
      }
    });
  }, []);

  const handleChange = (val) => {
    setActiveTab(val);
    if (val) {
      history.push(`/dashboard/dropdowns/${val.value}/`);
    } else history.push(`/dashboard/dropdowns/`);
  };

  return (
    <div className={css.dropdownsContainer}>
      <h1>Dropdowns</h1>
      <Select
        value={activeTab}
        onChange={handleChange}
        options={selectOptions}
      />

      {activeTab ? (
        <DropdownList tab={activeTab} />
      ) : (
        <p>Select a dropdown to edit.</p>
      )}
    </div>
  );
}

Dropdowns.propTypes = {};

export default withRouter(Dropdowns);
