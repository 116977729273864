import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useHistory } from 'react-router-dom';

export default function SaveConfirmationRedirectDialog({
  askForRedirect,
  type,
  id,
  mode,
  clearState,
}) {
  const history = useHistory();
  return (
    <Dialog open={askForRedirect} maxWidth="lg">
      <DialogTitle>
        Your changes have been saved. Would you like to manage other {type}?
      </DialogTitle>
      <DialogActions>
        <div style={{ display: 'flex', justifyItems: 'space-between' }}>
          <Button
            onClick={(...args) => {
              history.push(`/dashboard/${type}`);
            }}
          >
            Yes, take me to {type}
          </Button>
          <Button
            onClick={() => {
              if (mode === 'edit') {
                clearState();
              } else {
                history.push(`/dashboard/${type}/edit/${id}`);
              }
            }}
          >
            No, I want to stay here
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
