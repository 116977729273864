import styled from '@emotion/styled';
import { Alert } from '@mui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import {
  DEFAULT_IMAGE_CAROUSEL_ITEM,
  DEFAULT_PROGRAM_CAROUSEL_ITEM,
  isEmpty,
} from '../../utils';
import BuilderItem from './BuilderItem';
import ComponentItem from './ComponentItem';

const COMPONENTS = [
  {
    id: 1,
    type: 'header',
    size: 'h2',
    text: '',
    text_fr: '',
    alignment: 'center',
    topSpacing: 'auto',
    bottomSpacing: 'auto',
  },
  {
    id: 2,
    type: 'paragraph',
    text: '',
    text_fr: '',
    alignment: 'center',
    topSpacing: 'auto',
    bottomSpacing: 'auto',
  },
  {
    id: 3,
    type: 'quote',
    text: '',
    text_fr: '',
    alignment: 'center',
    topSpacing: 'auto',
    bottomSpacing: 'auto',
  },
  {
    id: 4,
    type: 'poll',
    pollId: null,
    topSpacing: 'auto',
    bottomSpacing: 'auto',
  },
  {
    id: 4,
    type: 'discountCarousel',
    variety: 'mostPopular',
    limit: 3,
    layout: 'smallThumbnails',
    topSpacing: 'auto',
    bottomSpacing: 'auto',
    title: '',
    title_fr: '',
  },
  {
    id: 4,
    type: 'businessCarousel',
    variety: 'mostPopular',
    limit: 3,
    layout: 'smallThumbnails',
    topSpacing: 'auto',
    bottomSpacing: 'auto',
  },
  {
    id: 5,
    type: 'imageCarousel',
    limit: 3,
    layout: 'smallThumbnails',
    images: [
      DEFAULT_IMAGE_CAROUSEL_ITEM,
      DEFAULT_IMAGE_CAROUSEL_ITEM,
      DEFAULT_IMAGE_CAROUSEL_ITEM,
    ],
    topSpacing: 'auto',
    bottomSpacing: 'auto',
    title: '',
    title_fr: '',
    orientation: 'left',
  },
  {
    id: 6,
    type: 'programCarousel',
    limit: 1,
    items: [DEFAULT_PROGRAM_CAROUSEL_ITEM],
    topSpacing: 'auto',
    bottomSpacing: 'auto',
  },
  {
    id: 7,
    type: 'categoryCarousel',
    entity: 'category',
    limit: 3,
    layout: 'smallThumbnails',
    items: [],
    topSpacing: 'auto',
    bottomSpacing: 'auto',
  },
  {
    id: 8,
    type: 'button',
    text: '',
    text_fr: '',
    url: '',
    alignment: 'center',
    topSpacing: 'auto',
    bottomSpacing: 'auto',
  },
  {
    id: 9,
    type: 'video',
    url: '',
    alignment: 'center',
    topSpacing: 'auto',
    bottomSpacing: 'auto',
  },
  {
    id: 10,
    type: 'image',
    item: null,
    item_fr: null,
    size: 'auto',
    unit: null,
    mediaItem: null,
    mediaItem_fr: null,
    alignment: 'center',
    topSpacing: 'auto',
    bottomSpacing: 'auto',
  },
  {
    id: 11,
    type: 'zPattern',
    image: null,
    image_fr: null,
    text: '',
    text_fr: '',
    orientation: 'left',
    mediaItem: null,
    mediaItem_fr: null,
    topSpacing: 'auto',
    bottomSpacing: 'auto',
  },
];

const Root = styled.div((props) => ({
  border: props.error ? '1px solid #ef5350' : '1px solid rgba(0,0,0,0.23)',
  borderRadius: 4,
  padding: 10,
  marginBottom: 10,
  display: 'flex',
  height: '90vh',
  alignItems: 'flex-start',
  overflow: 'auto',
  justifyContent: 'space-around',
}));

const Area = styled.div({
  maxWidth: '80%',
  minHeight: 200,
  flexBasis: '100%',
  borderRight: '1px solid #eee',
  paddingRight: 10,
  overflow: 'hidden',
});
const EmptyArea = styled.div({
  minHeight: 200,
});

const Components = styled.div({
  width: 300,
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 4,
  top: 0,
  position: 'sticky',
});

const BuilderArea = ({
  clearErrors,
  clearModuleError,
  errors,
  value,
  handleChange,
}) => {
  const [count, setCount] = useState(value.length);
  const [errorMessage, setErrorMessage] = useState('');
  const [{ isOver }, dropRef] = useDrop({
    accept: 'component',
    drop: (item) => {
      if (!item.inBasket) {
        handleChange([...value, { ...item, id: count, inBasket: true }]);
        setCount(count + 1);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const changeHandler = (stateArray) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    handleChange(stateArray);
  };

  useEffect(() => {
    if (typeof errors === 'string') setErrorMessage(errors);
    if (!isEmpty(errors)) clearErrors();
  }, [errors]);

  const addListItem = (hoverIndex, item) => {
    if (item) {
      const copiedStateArray = [...value];
      copiedStateArray.splice(hoverIndex, 0, {
        ...item,
        id: count,
        inBasket: true,
      });
      changeHandler(copiedStateArray);
      setCount(count + 1);
    }
  };

  const moveListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = value[dragIndex];
      if (dragItem) {
        const copiedStateArray = [...value];
        copiedStateArray.splice(dragIndex, 1);
        copiedStateArray.splice(hoverIndex, 0, dragItem);
        changeHandler(copiedStateArray);
      }
    },
    [value]
  );

  const updateBasket = (id, item) => {
    const copiedStateArray = [...value];
    copiedStateArray[id] = item;
    changeHandler(copiedStateArray);
  };

  const deleteItemFromBasket = (index) => {
    const copiedStateArray = [...value];
    copiedStateArray.splice(index, 1);
    changeHandler(copiedStateArray);
  };

  return (
    <>
      <Root error={errorMessage}>
        <Area>
          {value.map((item, index) => (
            <BuilderItem
              alignment={item.alignment}
              key={item.id}
              id={item.id}
              id_fr={item.id_fr}
              title={item.title}
              title_fr={item.title_fr}
              index={index}
              inBasket={item.inBasket}
              topSpacing={item.topSpacing}
              bottomSpacing={item.bottomSpacing}
              text={item.text}
              text_fr={item.text_fr}
              size={item.size}
              type={item.type}
              pollId={item.pollId}
              entity={item.entity}
              variety={item.variety}
              layout={item.layout}
              limit={item.limit}
              url={item.url}
              businesses={item.businesses}
              orientation={item.orientation}
              item={item.item}
              item_fr={item.item_fr}
              mediaItem={item.mediaItem}
              mediaItem_fr={item.mediaItem_fr}
              items={item.items}
              discounts={item.discounts}
              images={item.images}
              image={item.image}
              image_fr={item.image_fr}
              unit={item.unit}
              error={errors ? errors[index] : null}
              clearModuleError={clearModuleError}
              addListItem={addListItem}
              moveListItem={moveListItem}
              updateBasket={updateBasket}
              deleteItemFromBasket={deleteItemFromBasket}
            />
          ))}
          <EmptyArea ref={dropRef} />
        </Area>
        <Components>
          {COMPONENTS.map((item, index) => (
            <ComponentItem key={index} draggable item={item} />
          ))}
        </Components>
      </Root>
      {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
    </>
  );
};

export default BuilderArea;
