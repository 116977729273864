import React, { useEffect, useState } from "react";
import Slide from "./Slide";
import Slides from "./Slides";
import axios from "axios";
import css from "./Carousel.module.scss";

export default function Carousel() {
  const [error, setError] = useState({});
  const [slides, setSlides] = useState([]);
  const [selectedSlide, setSelectedSlide] = useState({});

  async function getSlides() {
    try {
      const res = await axios.get("/api/slides");
      setSlides(res.data.results);
    } catch (err) {
      setError(err.response.data);
    }
  }

  async function deleteSlide(slideId) {
    try {
      const res = await axios.delete(`/api/slides/${slideId}`);
      // getSlides();
      window.location.reload();
    } catch (err) {
      setError(err.response.data);
    }
  }

  useEffect(() => {
    getSlides();
  }, []);

  return (
    <div>
      <h1>Carousel</h1>

      <div className={css.inner}>
        <Slides slides={slides} selectSlide={setSelectedSlide} />
        <Slide
          slide={selectedSlide}
          slides={slides}
          deselectSlide={setSelectedSlide}
          addSlide={setSlides}
          deleteSlide={deleteSlide}
        />
      </div>
    </div>
  );
}
