import {
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';
import ImageSelect from 'src/components/Inputs/ImageSelect';
import styled from '@emotion/styled';
import CustomMUISelect from '../components/CustomMUISelect';
import { alignmentOptions } from 'src/utils';

const ImageModule = ({
  item,
  handleChange,
  handleCustomChange,
  handleImageChange,
  handleFrenchImageChange,
  showError,
}) => {
  return (
    <>
      <CustomMUISelect
        name="alignment"
        label="Alignment"
        options={alignmentOptions}
        value={item.alignment}
        onChange={handleChange}
      />
      <Stack direction="row" spacing={2} alignItems="baseline">
        <Input
          error={showError && item.unit !== 'auto' && !item.size}
          label="Size"
          type="number"
          variant="outlined"
          name="size"
          sx={{ flex: 1 }}
          autoFocus
          disabled={item.size === 'auto'}
          value={item.size}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            min: 0,
            max: item.unit === 'px' ? null : 100,
          }}
          onChange={handleChange}
          helperText={
            showError && item.unit !== 'auto' && !item.size ? 'Required' : null
          }
        />
        <ButtonGroup
          size="large"
          aria-label="large button group"
          variant="contained"
        >
          <Button
            disabled={item.size === 'auto'}
            onClick={() =>
              handleCustomChange({ ...item, size: 'auto', unit: null })
            }
            key="Auto"
          >
            Auto
          </Button>
          <Button
            disabled={item.unit === 'px'}
            onClick={() =>
              handleCustomChange({ ...item, size: '', unit: 'px' })
            }
            key="Pixels"
          >
            Pixels
          </Button>
          <Button
            disabled={item.unit === '%'}
            onClick={() => handleCustomChange({ ...item, size: '', unit: '%' })}
            key="Percent"
          >
            Percent
          </Button>
        </ButtonGroup>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        alignItems="baseline"
        justifyContent="space-between"
      >
        <ImageSelect
          initialImage={item.mediaItem}
          label="Image"
          name="headerImage"
          type=""
          handleSelect={handleImageChange}
          error={showError && !item.item ? 'Please Select an image' : null}
        />
        <ImageSelect
          initialImage={item.mediaItem_fr}
          label="Image (French)"
          name="headerImage"
          type=""
          handleSelect={handleFrenchImageChange}
        />
      </Stack>
    </>
  );
};

export default ImageModule;

const Input = styled(TextField)`
  width: 100%;
  margin: 15px 0;
`;
