import { combineReducers } from 'redux';
import affiliateReducer from './affiliateReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import pagesReducer from './pagesReducer';
import pollsReducer from './pollsReducer';
import mediaReducer from './mediaReducer';
import userReducer from './userReducer';
import postReducer from './postReducer';
import categoriesReducer from './categoriesReducer';
import discountReducer from './discountReducer';
import businessReducer from './businessReducer';

export default combineReducers({
  affiliates: affiliateReducer,
  auth: authReducer,
  pages: pagesReducer,
  polls: pollsReducer,
  media: mediaReducer,
  users: userReducer,
  posts: postReducer,
  categories: categoriesReducer,
  discounts: discountReducer,
  business: businessReducer,
  errors: errorReducer,
});
