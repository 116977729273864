import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material';
import React from 'react';
import ImageSelect from 'src/components/Inputs/ImageSelect';
import Wysiwyg from '../components/Wysiwyg';

const ZPatternModule = ({
  item,
  handleChange,
  handleTextChange,
  handleTextFRChange,
  handleImageChange,
  handleFrenchImageChange,
  showError,
}) => {
  return (
    <div>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="orientation-select">Orientation</InputLabel>
        <Select
          labelId="orientation-select"
          label="Orientation"
          name="orientation"
          value={item.orientation}
          onChange={handleChange}
        >
          <MenuItem value="left">Left</MenuItem>
          <MenuItem value="right">Right</MenuItem>
        </Select>
      </FormControl>
      <Stack
        direction="row"
        spacing={2}
        alignItems="baseline"
        justifyContent="space-between"
      >
        <ImageSelect
          initialImage={item.mediaItem}
          label="Image"
          name="headerImage"
          type=""
          handleSelect={handleImageChange}
          error={showError && !item.image ? 'Please Select an image' : null}
        />
        <ImageSelect
          initialImage={item.mediaItem_fr}
          label="Image (French)"
          name="headerImage"
          type=""
          handleSelect={handleFrenchImageChange}
        />
      </Stack>
      <Wysiwyg
        autoFocus
        name="content"
        label="Content"
        val={item.text}
        onChange={handleTextChange}
        error={showError && !item.text ? 'This field is required' : null}
      />
      <Wysiwyg
        name="content"
        label="Content (French)"
        val={item.text_fr}
        onChange={handleTextFRChange}
      />
    </div>
  );
};

export default ZPatternModule;
