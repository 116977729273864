import React from "react";
import PropTypes from "prop-types";
import Button from "../../Inputs/Button";
import moment from "moment";

const BusinessDiscountsListItem = ({ discount, businessId }) => {
  const {startDate, endDate, id, name, createdAt} = discount;
  return (
    <li>
      <div className="list-inner">
        <div className="name">{name}</div>
        <div className='date'>{moment(startDate ? startDate : createdAt).format("MM-DD-YYYY")}</div>
        <div className='date'>{endDate && moment(endDate).format("MM-DD-YYYY")}</div>
        <Button
          text="Edit"
          btnLink
          url={`/dashboard/businesses/${businessId}/discounts/${id}/edit`}
        />
      </div>
    </li>
  );
};

BusinessDiscountsListItem.propTypes = {
  businesses: PropTypes.object.isRequired,
};

export default BusinessDiscountsListItem;
