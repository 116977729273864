import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Inputs/Spinner';
import { isEmpty } from '../../utils';

import styled from 'styled-components';

import ListHeader from './ListHeader';
import ListItem from './ListItem';

const List = ({
  sortBy,
  setSort,
  data,
  loading,
  errors,
  columns,
  minWidth,
  showInvalid,
}) => {
  const columnWidth = `${100 / columns.length}%`;

  const list = isEmpty(errors) ? (
    data.map((el) => (
      <ListItem
        columns={columns}
        columnWidth={columnWidth}
        key={el.id}
        data={el}
        showInvalid={showInvalid}
      />
    ))
  ) : (
    <li>{errors.message}</li>
  );

  return (
    <Container>
      <ListGroup minWidth={minWidth}>
        <ListHeader
          setSort={setSort}
          sortBy={sortBy}
          columns={columns}
          columnWidth={columnWidth}
        />
        <ul>{loading ? <Spinner /> : list}</ul>
      </ListGroup>
    </Container>
  );
};

List.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

List.defaultProps = {
  data: [],
};

export default List;

const Container = styled.div`
  overflow: auto;
  width: 100%;
  max-width: 100%;
  word-break: normal;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 7px; /* height of horizontal scrollbar ← You're missing this */
    width: 4px; /* width of vertical scrollbar */
  }
  ::-webkit-scrollbar-thumb {
    background: #cfcfcf;
    border-radius: 10px;
  }
`;

const ListGroup = styled.div`
  width: 100%;
  min-height: 520px;
  border: 1px solid #efefef;
  border-radius: 5px;
  min-width: ${(p) => (p.minWidth ? `${p.minWidth}px` : '2000px')};


  ul {
    list-style: none;
    font-size: 14px;
    margin: 0;
    padding: 0;

    li {
      padding: 12px 20px;
      border-bottom: 1px solid #efefef;

      .button-container {
        display: flex;
        text-align: center;
        padding: 0 !important;

        .button:last-child {
          margin-right: 0;
        }
      }
    }

    li:hover {
      background: rgba(247, 247, 250, 0.6);
    }
    li:nth-last-child(1) {
      border: none;
    }
  }
`;
