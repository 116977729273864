/* eslint-disable import/extensions */
import React from 'react';
import PropTypes from 'prop-types';
import FroalaEditor from 'react-froala-wysiwyg';
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
// Plugins - Images and Files
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/css/plugins/image_manager.min.css';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/css/plugins/file.min.css';
// Plugins - Fonts, Text
import 'froala-editor/js/plugins/inline_style.min';
import 'froala-editor/js/plugins/inline_class.min';
import 'froala-editor/js/plugins/font_family.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/css/plugins/emoticons.min.css';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/css/plugins/special_characters.min.css';
import 'froala-editor/js/plugins/code_view.min.js';
// Plugins - Editor window
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/css/plugins/fullscreen.min.css';

import config from './froalaConfig';

const Wysiwyg = ({ name, label, val, onChange, error }) => {
  config.requestHeaders = {
    ['x-auth-token']: localStorage.token,
  };
  return (
    <div className="input-cntr wysiwyg">
      <label className="label" htmlFor={name}>
        {label}
      </label>
      <div style={{ marginTop: label ? '10px' : '0px' }} className="inner">
        <FroalaEditor model={val} onModelChange={onChange} config={config} />
      </div>
      {error ? (
        <small className="invalid-feedback">
          <i className="fas fa-times" />
          {error}
        </small>
      ) : null}
    </div>
  );
};

Wysiwyg.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  val: PropTypes.string.isRequired,
  toolbarInline: PropTypes.bool,
};

Wysiwyg.defaultProps = {
  toolbarInline: false,
};

export default Wysiwyg;
