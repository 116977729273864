/* eslint-disable import/prefer-default-export */
// General
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

//Affiliates
export const GET_AFFILIATES = 'GET_AFFILIATES';

// Auth
export const USER_LOADED = 'USER_LOADED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SET_LOADING = 'SET_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';

// Pages
export const CLEAR_PAGES = 'CLEAR_PAGES';
export const GET_PAGES = 'GET_PAGES';
export const SET_PAGES_LOADING = 'SET_PAGES_LOADING';

// Media
export const GET_MEDIA = 'GET_MEDIA';
export const SET_MEDIA_LOADING = 'SET_MEDIA_LOADING';

// Polls
export const GET_POLLS = 'GET_POLLS';
export const SET_POLLS_LOADING = 'SET_POLLS_LOADING';

// Businesses
export const GET_BUSINESS = 'GET_BUSINESS';
export const SET_BUSINESS_LOADING = 'SET_BUSINESS_LOADING';

// Posts
export const CLEAR_POSTS = 'CLEAR_POSTS';
export const GET_POSTS = 'GET_POSTS';
export const SET_POSTS_LOADING = 'SET_POSTS_LOADING';
export const CREATE_POST = 'CREATE_POST';

// User
export const GET_USERS = 'GET_USERS';
export const SET_USER_LOADING = 'SET_USER_LOADING';

// Categories
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_DROPDOWN = 'GET_CATEGORIES_DROPDOWN';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const EDIT_SUBCATEGORY = 'EDIT_SUBCATEGORY';
export const SET_CATEGORY_LOADING = 'SET_CATEGORY_LOADING';

// DISCOUNTS
export const GET_ALL_DISCOUNTS = 'GET_ALL_DISCOUNTS';
export const GET_ALL_DISCOUNTS_ERROR = 'GET_ALL_DISCOUNTS_ERROR';
export const GET_DISCOUNTS = 'GET_DISCOUNTS';
export const SET_DISCOUNTS_LOADING = 'SET_DISCOUNTS_LOADING';
export const CLEAR_DISCOUNTS = 'CLEAR_DISCOUNTS';
