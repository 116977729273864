import { Avatar, Chip, Divider, Stack } from '@mui/material';

export default function ProgramCarousel({ limit, items }) {
  return (
    <Stack flexDirection="row" alignItems="baseline" spacing={2}>
      {limit}
      <span>
        {items.map(({ heading }) =>
          heading ? <Chip sx={{ ml: 2 }} label={heading} /> : null
        )}
      </span>
    </Stack>
  );
}
