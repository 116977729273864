import axios from 'axios';
import {
  GET_USERS,
  SET_USER_LOADING,
  GET_ERRORS,
  CLEAR_ERRORS
} from '../types';

export const setLoading = (val = true) => async dispatch => {
  dispatch({
    type: SET_USER_LOADING,
    payload: val
  });
};

export const getUsers = (page, search, role) => async dispatch => {
  dispatch(setLoading());
  dispatch({ type: CLEAR_ERRORS });
  const params = {
    page,
    search,
    role
  };
  try {
    const res = await axios.get(`/api/users`, { params });
    dispatch({
      type: GET_USERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
    dispatch(setLoading(false));
  }
};
