import { Button } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { isEmpty } from '../../../../utils';
import Select from '../../../Inputs/Select';
import Pagination from '../../../Layout/Pagination';
import css from './PollSubmissions.module.scss';
import SubmissionPreview from './SubmissionPreview';

const ListItem = ({ item, setPreview, previewId, handleDelete }) => {
  const [confirmDel, setconfirmDel] = useState(false);

  useEffect(() => {
    if (confirmDel)
      setTimeout(() => {
        setconfirmDel(false);
      }, 3000);
    return () => {
      clearTimeout();
    };
  }, [confirmDel]);

  let activeStyle = {};
  let delStyle = {};
  if (previewId && item.id === previewId) {
    activeStyle = {
      border: '2px solid rgba(33, 134, 235, 1)',
    };
    delStyle = {
      border: '2px solid rgba(255, 67, 104, 1)',
    };
  }

  return (
    <li
      style={confirmDel ? delStyle : activeStyle}
      onClick={() => setPreview(item)}
      className={css.list_item}
    >
      <div>
        <em>id:</em> {item.id}
      </div>
      <div>{moment(item.createdAt).format('MMMM D YYYY, h:mm a')}</div>

      <div className={css.delete_container}>
        {confirmDel ? (
          <span onClick={() => handleDelete(previewId)}>
            <em>Confirm</em>
          </span>
        ) : (
          <span onClick={() => setconfirmDel(true)}>Delete</span>
        )}
      </div>
    </li>
  );
};

const List = ({ results, setPreview, previewId, handleDelete }) => {
  if (!isEmpty(results)) {
    const mapItems = results.map((el) => {
      return (
        <ListItem
          key={el.id}
          item={el}
          setPreview={setPreview}
          previewId={previewId}
          handleDelete={handleDelete}
        />
      );
    });

    return <ul className={css.list_group}>{mapItems}</ul>;
  }
  return <p>Looks like there's nothing here.</p>;
};

function PollSubmissions({ pollId }) {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState({ label: 10, value: 10 });

  const [preview, setPreview] = useState({});

  const [orderType, setOrderType] = useState({
    label: 'Ascending',
    value: 'ASC',
  });
  const [orderField, setOrderField] = useState({
    label: 'Date',
    value: 'createdAt',
  });

  const getData = async (page, csv = false) => {
    const path = `/api/polls/${pollId}/submissions/`;
    const params = {
      params: {
        page,
        limit: limit.value,
        csv,
        orderType: orderType.value,
        orderField: orderField.value,
      },
    };
    try {
      const res = await axios.get(path, params);
      if (csv) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `submissions-export-${moment().format('MM-DD-YYYY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
      } else {
        setData(res.data);
      }
    } catch (error) {
      if (error.response.status === 404) setData({});
      setErrors(error.response.data);
    }
  };

  // useEffect(() => {
  //   getData();
  // }, []);

  useEffect(() => {
    getData(page);
  }, [limit.value, orderType.value, orderField.value]);

  const changePage = (page) => {
    getData(page);
    setPage(page++);
  };

  const handleChangeLimit = (val) => {
    setPage(1);
    setLimit(val);
  };

  const handleDelete = async (submissionId) => {
    const path = `/api/polls/${pollId}/submissions/${submissionId}`;
    try {
      const res = await axios.delete(path);
      getData();
      if (submissionId === preview.id) {
        setPreview({});
      }
    } catch (error) {
      setErrors(error.response.data);
    }
  };

  return (
    <div className={css.container}>
      <h1>Poll Submissions</h1>

      <div className={css.inner}>
        <div className={css.left}>
          <h3>Submissions</h3>
          <Button onClick={() => getData(undefined, true)}>Export</Button>
          <div className={css.list_controls}>
            <Select
              label="Show:"
              value={limit}
              onChange={handleChangeLimit}
              options={[
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
              ]}
              clearable={false}
              width={50}
            />
            <Select
              label="Sort by:"
              value={orderField}
              onChange={setOrderField}
              options={[
                { label: 'Date', value: 'createdAt' },
                { label: 'User', value: 'userEmail' },
              ]}
              clearable={false}
              width={100}
            />
            <Select
              label="Sort:"
              value={orderType}
              onChange={setOrderType}
              options={[
                { label: 'Ascending', value: 'ASC' },
                { label: 'Descending', value: 'DESC' },
              ]}
              clearable={false}
              width={125}
            />
          </div>

          <span className={css.totalResults}>
            Total Results: {data.totalResults}
          </span>

          <List
            results={data.results}
            setPreview={setPreview}
            previewId={preview.id}
            handleDelete={handleDelete}
          />
          {data.totalResults ? (
            <Pagination
              totalRecords={data.totalResults}
              page={page}
              onPageChange={changePage}
              pageLimit={limit.value}
            />
          ) : null}
        </div>
        <div className={css.right}>
          <h3>View Submissions</h3>
          {!isEmpty(preview) ? <SubmissionPreview data={preview} /> : null}
        </div>
      </div>
    </div>
  );
}

PollSubmissions.propTypes = {};

export default PollSubmissions;
