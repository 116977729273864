import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Inputs/Button';
import moment from 'moment';

const BusinessListItem = ({ business }) => {
  const { affiliateProgram, name, startDate, endDate, id, createdAt } = business;
  return (
    <li>
      <div className="list-inner">
        <div className="name">{name}</div>
        <div className="name">{affiliateProgram ? affiliateProgram.name : null}</div>
        <div className="date"> {moment(startDate ? startDate : createdAt).format('MM-DD-YYYY')}</div>
        <div className="date"> {endDate && moment(endDate).format('MM-DD-YYYY')}</div>
        <Button text="Edit" btnLink url={`/dashboard/businesses/${id}/edit`} />
      </div>
    </li>
  );
};

BusinessListItem.propTypes = {
  businesses: PropTypes.object.isRequired,
};

export default BusinessListItem;
