import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '../../Inputs/Button';

const PostListItem = ({ post }) => {
  const { title, startDate, endDate, id, createdAt } = post;

  return (
    <li>
      <div className="list-inner">
        <div className="name">{title}</div>
        <div className="date">{moment(startDate ? startDate : createdAt).format('MM-DD-YYYY')}</div>
        <div className="date">{endDate && moment(endDate).format('MM-DD-YYYY')}</div>
        <div className="button-container">
          <Button
            className="btn-sm"
            text="Edit"
            btnLink
            url={`/dashboard/posts/edit/${id}`}
          />
        </div>
      </div>
    </li>
  );
};

PostListItem.propTypes = {
  post: PropTypes.object.isRequired,
};

export default PostListItem;
