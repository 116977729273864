import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Inputs/Spinner';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import axios from 'axios';
import css from './FileUpload.module.scss';
import Button from './Button';
import { isEmpty } from '../../utils';

function NewFileUpload({
  label,
  imgType,
  getImageUrl,
  imgPreview,
  aspectRatio,
}) {
  // Component logic
  const [errors, setErrors] = useState({});
  const [showCropper, setShowCropper] = useState(false);
  const [file, setFile] = useState(null);
  const [filename, setFileName] = useState('');
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (imgPreview) setPreview(imgPreview);

    return function cleanup() {
      setPreview(null);
    };
  }, [imgPreview]);

  const [loading, setLoading] = useState(false);

  const handleSelectFile = (e) => {
    setErrors({});
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size / 1024 >= 2048) {
        setErrors('File size must be under 2MB');
      } else {
        setPreview(null);
        const reader = new FileReader();
        reader.addEventListener('load', () => setFile({ file: reader.result }));
        setFile(window.URL.createObjectURL(e.target.files[0]));
        setFileName(e.target.files[0].name);
        //   reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  //   Handle Save
  const handleSave = async () => {
    setLoading(true);
    const path = '/api/files';
    const payload = new FormData();

    const config = {
      params: { crop: showCropper },
    };

    // Construct a blob
    let blob;
    blob = await fetch(file).then((r) => r.blob());
    blob.lastModifiedDate = new Date();
    setFileName(blob.name);
    payload.append(`${imgType}`, blob, filename);
    if (showCropper) {
      payload.append(`${imgType}_left`, canvas.x);
      payload.append(`${imgType}_top`, canvas.y);
      payload.append(`${imgType}_width`, canvas.width);
      payload.append(`${imgType}_height`, canvas.height);
    }
    try {
      const res = await axios.post(path, payload, config);
      setLoading(false);
      setFile(null);
      setFileName('');
      setPreview(res.data[imgType]);
      getImageUrl(imgType, res.data[imgType]);
    } catch (err) {
      setErrors(err.response.data);
    }
  };

  const handleCancel = () => {
    setFile(null);
    setFileName('');
    setShowCropper(false);
  };

  //  Cropper
  const [canvas, setCanvas] = useState(null);
  const cropperRef = useRef(null);
  const onCrop = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    setCanvas(cropper.getData(true));
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };

  const renderCropper = () => {
    if (showCropper && file)
      return (
        <div className={css.cropper_container}>
          <Cropper
            src={file}
            style={{ height: 400, width: '100%' }}
            // Cropper.js options
            guides={false}
            crop={onCrop}
            ref={cropperRef}
            initialAspectRatio={aspectRatio}
            aspectRatio={aspectRatio}
            viewMode={1}
          />
          <div className={css.btn_container}>
            <Button text={`Save ${imgType}`} onClick={handleSave} />
            <Button text="Cancel" onClick={handleCancel} />
          </div>
        </div>
      );
  };

  // Decide whether to render the cropper or just upload the image
  const askToCrop = () => {
    if (file) {
      return (
        <div className={css.btn_container}>
          <Button text="Crop" onClick={() => setShowCropper(true)} />
          <Button text="I dont want to crop" onClick={handleSave} />
        </div>
      );
    }
  };

  if (loading) return <Spinner />;

  return (
    <div className={css.container}>
      <label>{label}</label>
      <input
        id={`input_${imgType}`}
        type="file"
        accept="image/png, image/jpeg"
        onChange={handleSelectFile}
      />

      {preview ? <img className={css.preview} src={preview} alt="" /> : null}

      {showCropper ? renderCropper() : askToCrop()}

      {showCropper ? <hr /> : null}

      {!isEmpty(errors) ? <small className={css.errors}>{errors}</small> : null}
    </div>
  );
}

NewFileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  imgType: PropTypes.string.isRequired, // Thumbnail or Header
  getImageUrl: PropTypes.func.isRequired,
  imgPreview: PropTypes.string,
  aspectRatio: PropTypes.number,
};

NewFileUpload.defaultProps = {
  aspectRatio: 16 / 9,
};

export default NewFileUpload;
