import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { isEmpty } from '../../../utils';
import NewFileUpload from '../../Inputs/NewFileUpload';
import Select from '../../Inputs/Select';
import TextField from '../../Inputs/TextField';
import css from './MediaLibraryEntry.module.scss';

const typeOptions = [
  { label: 'Page Header', value: 'Page Header', aspectRatio: 2560 / 704 },
  { label: 'Desktop Header', value: 'Desktop', aspectRatio: 16 / 10 },
  { label: 'Mobile Header', value: 'Mobile', aspectRatio: 512 / 683 },
  { label: 'Thumbnail', value: 'Thumbnail', aspectRatio: 1 / 1 },
  { label: 'Product', value: 'Product', aspectRatio: 1 / 1 },
];

function MediaLibraryEntry({ image, closeDialog, getData }) {
  const [internalName, setInternalName] = useState('');
  const [altText, setAltText] = useState('');
  const [type, setType] = useState(null);
  const [imgUrl, setImgUrl] = useState('');
  const [mode, setMode] = useState('create');
  const [file, setFile] = useState(null);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmCloneDialogOpen, setConfirmCloneDialogOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [mediaUsedError, setMediaUsedError] = useState(null);
  const [mediaUpdated, setMediaUpdated] = useState(false);

  const resetState = () => {
    setInternalName('');
    setAltText('');
    setType(null);
    setImgUrl('');
    setMode('create');
  };

  useEffect(() => {
    if (!isEmpty(image)) {
      setMode('edit');
      setInternalName(image.label);
      setAltText(image.altText);
      setImgUrl(image.url);
      setType(() => {
        let type;
        typeOptions.forEach((el) => {
          if (el.value === image.type) type = el;
        });
        return type;
      });
    }

    return () => {
      resetState();
    };
  }, [image]);

  const handleSelect = (val) => {
    if (val !== type) {
      setType(val);
      setImgUrl('');
    }
  };
  const handleSaveImage = (img) => {
    if (mode === 'edit') setMediaUpdated(true);
    setImgUrl(img);
  };

  const handleClone = async () => {
    const path = `/api/media/${image.id}/duplicate`;
    try {
      await axios.post(path);
      if (getData) getData();
      closeDialog();
    } catch (err) {
      setErrors(err.response.data);
    }
  };

  const handleDelete = async () => {
    const path = `/api/media/${image.id}`;
    try {
      await axios.delete(path);
      if (getData) getData();
      setConfirmDialogOpen(false);
      closeDialog();
    } catch (err) {
      setErrors(err.response.data);
      if (
        err.response.data.message.includes(
          'Media is being used on the following'
        )
      ) {
        setMediaUsedError({ __html: err.response.data.message });
      }
    }
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
    setMediaUsedError(null);
  };

  const handleSave = async () => {
    setErrors({});
    const path = mode === 'create' ? '/api/media' : `/api/media/${image.id}`;
    const method = mode === 'create' ? 'post' : 'put';

    const payload = {
      label: internalName,
      altText: altText,
      type: type?.value,
      url: imgUrl,
    };

    try {
      await axios[method](path, payload);
      if (getData) getData();
      closeDialog();
    } catch (err) {
      setErrors(err.response.data);
    }
  };

  let disableSelect = false;
  if (mode === 'edit' || file) disableSelect = true;

  const renderUpload = () => {
    if (mode === 'create') {
      return (
        <NewFileUpload
          label="Image Upload"
          imgType="image"
          getImageUrl={handleSaveImage}
          getFile={setFile}
          imgPreview={imgUrl}
          ar={type?.aspectRatio}
          type={type?.value}
        />
      );
    } else if (mode === 'edit') {
      return (
        <Stack>
          {!mediaUpdated && (
            <div>
              <img className={css.preview} src={imgUrl} alt="" />
            </div>
          )}
          <NewFileUpload
            label="Replace Image"
            imgType="image"
            getImageUrl={handleSaveImage}
            getFile={setFile}
            imgPreview={imgUrl}
            ar={type?.aspectRatio}
            type={type?.value}
          />
        </Stack>
      );
    }
  };

  const dialogTitle = mode === 'create' ? 'Upload a new image' : 'Edit image';
  let aspectRatioText = '';

  if (type && type.value === 'Page Header') aspectRatioText = '2560 : 704';
  if (type && type.value === 'Mobile') aspectRatioText = '512 : 683';
  if (type && type.value === 'Desktop') aspectRatioText = '16 : 10';
  if (type && type.value === 'Thumbnail') aspectRatioText = '1 : 1';
  if (type && type.value === 'Product') aspectRatioText = '1 : 1';

  return (
    <div className={css.container}>
      <div>
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>

        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <Select
              name="type"
              label="Image type"
              value={type}
              options={typeOptions}
              onChange={handleSelect}
              helperText={`This will affect the aspect ratio of the image. Current: ${aspectRatioText}`}
              disabled={disableSelect}
              clearable={true}
            />
            <TextField
              name="internalName"
              label="Internal Name"
              helperText="Used within the cms to identify images, max 50 characters."
              value={internalName}
              maxLength={50}
              onChange={(e) => setInternalName(e.target.value)}
              error={!isEmpty(errors) ? errors.label : null}
            />
            <TextField
              name="altText"
              label="Image alt text"
              helperText="Used for SEO purposes"
              value={altText}
              maxLength={100}
              onChange={(e) => setAltText(e.target.value)}
              error={!isEmpty(errors) ? errors.altText : null}
            />
          </div>
          <div className={css.right}>{renderUpload()}</div>
        </div>
      </div>
      <div>
        <DialogActions>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {mode === 'edit' ? (
                <>
                  <Button
                    onClick={() => setConfirmCloneDialogOpen(true)}
                    color="primary"
                  >
                    Clone
                  </Button>
                  <Button
                    onClick={() => setConfirmDialogOpen(true)}
                    color="secondary"
                  >
                    Delete
                  </Button>
                </>
              ) : null}
            </div>
            <div>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button
                disabled={imgUrl ? false : true}
                onClick={handleSave}
                color="primary"
              >
                Save
              </Button>
            </div>
          </div>
        </DialogActions>
      </div>

      <Dialog
        open={confirmDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete '${internalName}?'`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {mediaUsedError
              ? 'Cannot delete media.'
              : ` Are you sure you want to delete '${internalName}?'`}
          </DialogContentText>
          {!isEmpty(errors) ? (
            <DialogContentText id="alert-dialog-description">
              <div dangerouslySetInnerHTML={mediaUsedError} />
            </DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button
            disabled={isEmpty(mediaUsedError) ? false : true}
            onClick={handleDelete}
            color="secondary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmCloneDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Clone '${internalName}?'`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to clone '${internalName}'?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmCloneDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleClone} color="primary" autoFocus>
            Clone
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

MediaLibraryEntry.propTypes = {};

export default MediaLibraryEntry;
