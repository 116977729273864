import { GET_BUSINESS, SET_BUSINESS_LOADING } from '../types';

const initialState = {
  businesses: {},
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_BUSINESS:
      return {
        businesses: payload,
        loading: false,
      };
    case SET_BUSINESS_LOADING:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
}
