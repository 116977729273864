import styled from '@emotion/styled';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { alignmentOptions } from 'src/utils';
import CustomMUISelect from '../components/CustomMUISelect';

const Header = ({
  alignment,
  showError,
  text,
  text_fr,
  size,
  handleChange,
  handleChangeRemoveError,
  handleTextChange,
  handleTextFRChange,
  handleSizeChange,
}) => {
  return (
    <>
      <CustomMUISelect
        name="alignment"
        label="Alignment"
        options={alignmentOptions}
        value={alignment}
        onChange={handleChange}
      />
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="size-select">Size</InputLabel>
        <Select
          labelId="size-select"
          label="Size"
          name="size"
          value={size}
          onChange={handleChange}
        >
          <MenuItem value="h1">H1</MenuItem>
          <MenuItem value="h2">H2</MenuItem>
          <MenuItem value="h3">H3</MenuItem>
          <MenuItem value="h4">H4</MenuItem>
          <MenuItem value="h5">H5</MenuItem>
          <MenuItem value="h6">H6</MenuItem>
        </Select>
      </FormControl>
      <Input
        error={showError && !text}
        label="Text"
        variant="outlined"
        name="text"
        autoFocus
        value={text}
        onChange={handleChangeRemoveError}
        helperText={showError && !text ? 'Required' : null}
        inputProps={{ maxLength: 100 }}
      />
      <Input
        label="Text (French)"
        variant="outlined"
        name="text_fr"
        value={text_fr}
        onChange={handleChange}
        inputProps={{ maxLength: 100 }}
      />
    </>
  );
};

export default Header;

const Input = styled(TextField)`
  width: 100%;
  margin: 15px 0;
`;
