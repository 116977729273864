/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import axios from 'axios';
import isURL from 'validator/lib/isURL';

export const setUTCOffsetHeader = () => {
  axios.defaults.headers.common['x-utc-offset'] = moment().format('Z');
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const ensurePasswordsMatch = (pswd, pswd2) => pswd === pswd2;

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function getURLError(showError, url) {
  if (showError) {
    if (!url) return 'Required';
    else if (!isURL(url)) return 'Must be a valid URL';
  }
  return '';
}

export const DEFAULT_IMAGE_CAROUSEL_ITEM = {
  id: null,
  id_fr: null,
  text: '',
  text_fr: '',
  url: '',
  color: '#000',
  mediaItem: null,
  mediaItem_fr: null,
};

export const DEFAULT_PROGRAM_CAROUSEL_ITEM = {
  backgroundImage: null,
  backgroundImage_fr: null,
  heading: '',
  heading_fr: '',
  subHeading: '',
  subHeading_fr: '',
  text: '',
  text_fr: '',
  image: null,
  image_fr: null,
  backgroundImageMedia: null,
  backgroundImageMobileMedia: null,
  imageItem: null,
  imageItem_fr: null,
  url: '',
};

export const spacingOptions = [
  { value: 'auto', label: 'Auto' },
  { value: 'small', label: 'Small' },
  { value: 'medium', label: 'Medium' },
  { value: 'large', label: 'Large' },
  { value: 'none', label: 'None' },
];

export const alignmentOptions = [
  { value: 'left', label: 'Left' },
  { value: 'center', label: 'Center' },
  { value: 'right', label: 'Right' },
];

export const normalizeFloat = (value) =>
  `${
    Math.round((typeof value === 'string' ? parseFloat(value) : value) * 100) /
    100
  }`;

export const normalizeFloatNullable = (value) =>
  value === null ||
  value === undefined ||
  value === '' ||
  (typeof value === 'string' && isNaN(parseFloat(value))) ||
  (typeof value !== 'number' && typeof value !== 'string')
    ? null
    : normalizeFloat(value);

export const areNullableFloatsEqual = (value1, value2) =>
  normalizeFloatNullable(value1) === normalizeFloatNullable(value2);
