import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { getBusinesses } from "../../../redux/actions/businessActions";
import { getDiscounts } from "../../../redux/actions/discountActions";
import Header from "../../Layout/Header";
import DiscountList from "./DiscountList";
import Pagination from "../../Layout/Pagination";
import DiscountSearch from "./DiscountSearch";
import axios from "axios";

const Discounts = ({ getDiscounts, match, discounts, history, errors }) => {
  const { params } = match;
  const { results, totalResults } = discounts;
  const dispatch = useDispatch();

  const businessState = useSelector((state) => state.business);

  const [error, setErrors] = useState({});

  const [search, setSearch] = useState({
    name: "",
    categories: [],
    category: null,
    business: null,
    orderType: { label: "Ascending", value: "ASC" },
    orderField: { label: "Name", value: "name" },
  });

  const [categories, setCategories] = useState([]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    getDiscounts(page);
    if (!businessState.businesses.rows) dispatch(getBusinesses());
    axios
      .get("/api/categories", { params: { dropdown: true } })
      .then((res) => {
        const groupOptions = res.data.map((el) => {
          return {
            label: el.name,
            options: el.subcategories.map((el) => {
              return { label: el.name, value: el.id };
            }),
          };
        });
        setCategories(groupOptions);
      })
      .catch((err) => {
        setErrors(err.response.data);
      });
  }, []);

  const { name, business, category, orderType, orderField } = search;

  const orderString = `${orderField.value},${orderType.value}`;

  const changePage = (page) => {
    const cat = category ? category.value : undefined;
    const biz = business ? business.value : undefined;
    getDiscounts(page, name, cat, orderString);
    setPage(page++);
  };

  const handleSearch = (e, name, subcategoryId, order, businessId) => {
    e.preventDefault();
    getDiscounts(1, name, subcategoryId, order, businessId);
    setPage(1);
  };

  return (
    <div>
      <Header
        title="Discounts"
        btnText="New discount"
        btnLink
        url="/dashboard/discounts/add"
      />
      <DiscountSearch
        handleSearch={handleSearch}
        setSearch={setSearch}
        search={search}
        categories={categories}
        businesses={businessState.businesses.rows || []}
      />
      <DiscountList discounts={results} errors={errors} />
      {totalResults ? (
        <Pagination
          totalRecords={totalResults}
          page={page}
          onPageChange={changePage}
        />
      ) : null}
    </div>
  );
};

Discounts.propTypes = {
  discounts: PropTypes.object.isRequired,
  getDiscounts: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  discounts: state.discounts.discounts,
  errors: state.errors,
});

const mapDispatchToProps = {
  getDiscounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Discounts);
