/* eslint-disable react/no-did-update-set-state */
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import slugify from 'slugify';
import { a11yProps, isEmpty } from '../../../utils';
import Button from '../../Inputs/Button';
import CreatableSelect from '../../Inputs/CreatableSelect';
import DatePicker from '../../Inputs/DatePicker';
import ImageSelect from '../../Inputs/ImageSelect';
import Select from '../../Inputs/Select';
import Spinner from '../../Inputs/Spinner';
import TextField from '../../Inputs/TextField';
import Wysiwyg from '../../Inputs/Wysiwyg/Wysiwyg';
import LanguageSwitcher from '../../Layout/LanguageSwitcher';
import TabPanel from '../../Layout/TabPanel';
import css from './Post.module.scss';
import { Tab, Tabs } from '@mui/material';
import SEOFields from '../common/SEOFields';
import SaveConfirmationRedirectDialog from '../common/SaveConfirmationRedirectDialog';

const publishOptions = [
  { value: false, label: 'Not published' },
  { value: true, label: 'Published' },
];

export class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      tab: 'en',
      tagOptions: [],
      tagOptionsFR: [],

      // SEO
      metaDescription: '',
      twitterSummary: '',
      openGraphTitle: '',
      openGraphType: '',
      openGraphUrl: '',
      // openGraphImage: null,
      openGraphDescription: '',

      // Post data
      thumbnail: null,
      thumbnail_fr: null,
      headerImage: null,
      headerImageMobile: null,
      headerImage_fr: null,
      headerImageMobile_fr: null,
      published: { value: true, label: 'Published' },
      startDate: null,
      endDate: null,
      slug: '',
      en: {
        title: '',
        content: '',
        excerpt: '',
        tags: [],
        summary: '',
      },
      fr: {
        title: '',
        content: '',
        excerpt: '',
        tags: [],
        summary: '',
      },
      loading: false,

      selectedTab: 0,
      askForRedirect: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { mode, id } = this.props.match.params;

    if (prevProps.match.params.mode !== mode) {
      window.location.reload();
    }
  };

  fetchTags = async () => {
    try {
      const res = await axios.get('/api/tags', { params: { dropdown: true } });

      this.setState({
        tagOptions: res.data.map((el) => ({
          value: el,
          label: el,
        })),
      });
    } catch (err) {}

    try {
      const resFR = await axios.get('/api/tagsfr', {
        params: { dropdown: true },
      });
      this.setState({
        tagOptionsFR: resFR.data.map((el) => ({
          value: el,
          label: el,
        })),
      });
    } catch (error) {}
  };

  componentDidMount = async () => {
    const { mode, id } = this.props.match.params;
    if (mode === 'edit' && id) {
      this.setState({ loading: true });
      try {
        const res = await axios.get(`/api/posts/${id}`);
        this.updateState(res.data);
      } catch (err) {
        this.setState({ err: err.response.data });
      }
    }

    this.fetchTags();
  };

  componentWillUnmount = () => {
    this.setState({});
  };

  updateState = (post) => {
    this.setState({
      thumbnail: post.thumbnail,
      thumbnail_fr: post.thumbnail_fr,
      headerImage: post.headerImage,
      headerImageMobile: post.headerImageMobile,
      headerImage_fr: post.headerImage_fr,
      headerImageMobile_fr: post.headerImageMobile_fr,
      published: publishOptions.find((el) => el.value === post.published),
      startDate: post.startDate
        ? moment(post.startDate).startOf('day').toDate()
        : null,
      endDate: post.endDate ? moment(post.endDate).endOf('day').toDate() : null,
      slug: post.slug,
      metaDescription: post.metaDescription,
      twitterSummary: post.twitterSummary,
      openGraphTitle: post.openGraphTitle,
      openGraphType: post.openGraphType,
      openGraphUrl: post.openGraphUrl,
      // openGraphImage: post.openGraphImage,
      openGraphDescription: post.openGraphDescription,
      en: {
        title: post.title,
        content: post.content,
        summary: post.summary,
        tags: post.tags.map((el) => {
          return { value: el, label: el };
        }),
      },
      fr: {
        title: post.title_fr,
        content: post.content_fr,
        summary: post.summary_fr,
        tags: post.tagsfr.map((el) => {
          return { value: el, label: el };
        }),
      },
      loading: false,
    });
  };

  changeTabs = (tab) => {
    this.setState({ tab: tab });
  };
  onChange = (e) => {
    const { tab } = this.state;
    this.setState({
      [tab]: { ...this.state[tab], [e.target.name]: e.target.value },
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleWysiwyg = (content) => {
    const { tab } = this.state;
    this.setState({
      [tab]: {
        ...this.state[tab],
        content: content,
      },
    });
  };
  handleStartDate = (startDate) => {
    this.setState({ startDate });
  };

  handleEndDate = (endDate) => {
    const { startDate } = this.state;
    this.setState({ endDate });

    if (moment(startDate).isAfter(endDate)) {
      this.setState({ startDate: endDate });
    }
  };

  handleSelectPublish = (published) => {
    this.setState({ published });
  };

  handleCreatable = (tags) => {
    const { tab } = this.state;
    this.setState({ ...this.state, [tab]: { ...this.state[tab], tags } });
  };

  handleFileUpload = (imgType, file) => {
    this.setState({ [imgType]: file });
  };

  handleSelectImage = (image, name) => {
    this.setState({ [name]: image });
  };

  handleSubmit = async () => {
    const { history, snackbar } = this.props;
    const { mode, id } = this.props.match.params;
    const {
      en,
      fr,
      slug,
      headerImage,
      headerImageMobile,
      headerImage_fr,
      headerImageMobile_fr,
      thumbnail,
      thumbnail_fr,
      startDate,
      endDate,
      published,
      metaDescription,
      twitterSummary,
      openGraphTitle,
      openGraphType,
      openGraphUrl,
      // openGraphImage,
      openGraphDescription,
    } = this.state;
    const payload = {
      title: en.title,
      content: en.content,
      summary: en.summary,
      tags: en.tags.map((el) => el.value),
      title_fr: fr.title,
      content_fr: fr.content,
      summary_fr: fr.summary,
      tagsfr: fr.tags.map((el) => el.value),
      headerImage: !isEmpty(headerImage) ? headerImage.id : null,
      headerImageMobile: !isEmpty(headerImageMobile)
        ? headerImageMobile.id
        : null,
      headerImage_fr: !isEmpty(headerImage_fr) ? headerImage_fr.id : null,
      headerImageMobile_fr: !isEmpty(headerImageMobile_fr)
        ? headerImageMobile_fr.id
        : null,
      thumbnail: !isEmpty(thumbnail) ? thumbnail.id : null,
      thumbnail_fr: !isEmpty(thumbnail_fr) ? thumbnail_fr.id : null,
      startDate: moment(startDate).startOf('day'),
      endDate: moment(endDate).endOf('day'),
      published: published.value,
      slug: mode === 'edit' ? undefined : slug,
      metaDescription,
      twitterSummary,
      openGraphTitle,
      openGraphType,
      openGraphUrl,
      // openGraphImage,
      openGraphDescription,
    };

    const requestMethod = mode === 'edit' ? 'put' : 'post';
    const path = mode === 'edit' ? `/api/posts/${id}` : '/api/posts';

    try {
      const res = await axios[requestMethod](path, payload);
      this.setState({ askForRedirect: true, errors: {}, id: res.data.id });
      snackbar.setSnackbarMessage('Success');
    } catch (error) {
      const obj = error.response.data;
      if (
        obj.hasOwnProperty('title_fr') ||
        obj.hasOwnProperty('content_fr') ||
        obj.hasOwnProperty('summary_fr') ||
        obj.hasOwnProperty('tagsfr')
      ) {
        this.setState({ tab: 'fr' });
      } else this.setState({ tab: 'en' });
      this.setState({ errors: obj });
      snackbar.setSnackbarMessage("There's an error, please check your input.");
    }
  };

  handleDelete = async () => {
    const { id } = this.props.match.params;
    const { history } = this.props;
    try {
      const res = await axios.delete(`/api/posts/${id}`);
      history.push('/dashboard/posts');
    } catch (error) {
      this.setState({ errors: error.response.data });
    }
  };

  handleDeleteBtn = () => {
    if (this.state.deleteMode) {
      return (
        <div style={{ display: 'flex' }}>
          <Button
            styles={['btn', 'btn_red']}
            text="Confirm"
            onClick={this.handleDelete}
          />
          <Button
            text="Cancel"
            onClick={() => this.setState({ deleteMode: false })}
          />
        </div>
      );
    }
    return (
      <Button
        onClick={() => this.setState({ deleteMode: true })}
        text="Delete"
        styles={['btn', 'btn_red']}
      />
    );
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  render() {
    const {
      errors,
      title,
      url,
      slug,
      en,
      fr,
      tab,
      startDate,
      endDate,
      thumbnail,
      thumbnail_fr,
      headerImage,
      headerImageMobile,
      headerImage_fr,
      headerImageMobile_fr,
      published,
      tagOptions,
      tagOptionsFR,
      deleteMode,
      loading,
      selectedTab,
      metaDescription,
      twitterSummary,
      openGraphTitle,
      openGraphType,
      openGraphUrl,
      // openGraphImage,
      openGraphDescription,
      askForRedirect,
    } = this.state;

    const { mode, id } = this.props.match.params;

    let titleErr;
    let summaryErr;
    let contentErr;
    let tagErr;

    if (!isEmpty(errors)) {
      titleErr = tab === 'fr' ? errors.title_fr : errors.title;
      summaryErr = tab === 'fr' ? errors.summary_fr : errors.summary;
      contentErr = tab === 'fr' ? errors.content_fr : errors.content;
      tagErr = tab === 'fr' ? errors.tagsfr : errors.tags;
    }

    const domain = process.env.REACT_APP_WEB_DOMAIN;

    if (!loading)
      return (
        <div className={css.postContainer}>
          <SaveConfirmationRedirectDialog
            askForRedirect={askForRedirect}
            id={this.state.id}
            type="posts"
            mode={mode}
            clearState={() => this.setState({ askForRedirect: false })}
          />

          <h1>{mode === 'add' ? 'New post' : 'Edit post'}</h1>
          <p>
            <strong>Url: </strong>
            {`${domain}/blog/post/${slugify(slug, {
              lower: true,
            })}`}
          </p>

          <LanguageSwitcher language={tab} changeLanguage={this.changeTabs} />

          <Tabs value={selectedTab} onChange={this.handleChangeTab}>
            <Tab label="General Information" {...a11yProps(0)} />
            <Tab label="SEO" {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={selectedTab} index={0}>
            <Select
              value={published}
              name="published"
              label="Publishing options"
              options={publishOptions}
              className="input-lg"
              onChange={this.handleSelectPublish}
              error={errors.published}
              clearable={false}
            />

            <TextField
              value={tab === 'en' ? en.title : fr.title}
              name="title"
              label={`Post title (${tab})`}
              placeholder="New page"
              required
              className="input-lg"
              onChange={this.onChange}
              error={titleErr}
              maxLength={100}
            />

            <TextField
              value={slug}
              name="slug"
              label="Page url"
              placeholder="url"
              className="input-lg"
              onChange={this.handleChange}
              disabled={mode === 'edit' ? true : false}
              error={errors.slug}
            />

            <DatePicker
              name="endDate"
              selected={endDate}
              label="End date"
              onChange={this.handleEndDate}
              error={errors.endDate}
              minDate={new Date()}
              isClearable={true}
            />
            <DatePicker
              name="startDate"
              selected={startDate}
              label="Start date"
              onChange={this.handleStartDate}
              error={errors.startDate}
              maxDate={endDate}
              isClearable={true}
            />
            <CreatableSelect
              value={tab === 'en' ? en.tags : fr.tags}
              name="tags"
              label={`Tags (${tab})`}
              className="input-lg"
              isMulti
              onChange={this.handleCreatable}
              options={tab === 'en' ? tagOptions : tagOptionsFR}
              maxLength={15}
              error={tagErr}
            />

            <Wysiwyg
              name="content"
              label={`Page content (${tab})`}
              val={tab === 'en' ? en.content : fr.content}
              onChange={this.handleWysiwyg}
              error={contentErr}
            />

            <TextField
              value={tab === 'en' ? en.summary : fr.summary}
              name="summary"
              label="Post summary"
              placeholder="Post summary"
              className="input-lg"
              onChange={this.onChange}
              error={summaryErr}
              maxLength={200}
            />
            <ImageSelect
              initialImage={headerImage}
              label="Header image (en)"
              name="headerImage"
              type="Desktop"
              handleSelect={this.handleSelectImage}
              error={errors.headerImage}
            />
            <ImageSelect
              initialImage={headerImage_fr}
              label="Header image (fr)"
              name="headerImage_fr"
              type="Desktop"
              handleSelect={this.handleSelectImage}
            />

            <ImageSelect
              initialImage={headerImageMobile}
              label="Header image - Mobile (en)"
              name="headerImageMobile"
              type="Mobile"
              handleSelect={this.handleSelectImage}
              error={errors.headerImageMobile}
            />
            <ImageSelect
              initialImage={headerImageMobile_fr}
              label="Header image - Mobile (fr)"
              name="headerImageMobile_fr"
              type="Mobile"
              handleSelect={this.handleSelectImage}
            />

            <ImageSelect
              initialImage={thumbnail}
              label="Thumbnail (en)"
              name="thumbnail"
              type="Thumbnail"
              handleSelect={this.handleSelectImage}
              error={errors.thumbnail}
            />
            <ImageSelect
              initialImage={thumbnail_fr}
              label="Thumbnail (fr)"
              name="thumbnail_fr"
              type="Thumbnail"
              handleSelect={this.handleSelectImage}
            />
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <SEOFields
              value={this.state}
              errors={this.state.errors}
              handleChange={this.handleChange}
            />
          </TabPanel>

          <div className="btn_appart">
            <Button
              text={mode === 'edit' ? 'Save changes' : 'Create post'}
              onClick={this.handleSubmit}
            />

            {mode === 'edit' ? this.handleDeleteBtn() : null}
          </div>
        </div>
      );

    return <Spinner />;
  }
}

Post.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Post);
