import React from "react";
import Spinner from "../../Inputs/Spinner";
import { isEmpty } from "../../../utils";
import BusinessDiscountsListItem from "./BusinessDiscountsListItem";
import BusinessDiscountsListHeader from "./BusinessDiscountsListHeader";
import PropTypes from "prop-types";

const BusinessDiscountsList = ({ discounts, loading, errors, businessId }) => {
  const list = isEmpty(errors) ? (
    discounts.map((discount) => (
      <BusinessDiscountsListItem
        key={discount.id}
        discount={discount}
        businessId={businessId ? businessId : ""}
      />
    ))
  ) : (
    <li>{errors.message}</li>
  );
  return (
    <div className="list-group">
      <ul>
        <BusinessDiscountsListHeader />
        {loading ? <Spinner /> : list}
      </ul>
    </div>
  );
};

BusinessDiscountsList.propTypes = {
  discounts: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

BusinessDiscountsList.defaultProps = {
  discounts: [],
};

export default BusinessDiscountsList;
