import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { normalizeFloat } from '../../../utils';
import Button from '../../Inputs/Button';
import Spinner from '../../Inputs/Spinner';
import TextField from '../../Inputs/TextField';
import css from './System.module.scss';

const isCashbackValueAllowed = (values) => {
  const { value } = values;
  return value >= 0;
};

export default function ReferralValue({
  endpoint,
  fieldName,
  fieldLabel,
  confirmationText,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [cashbackValue, setCashbackValue] = useState('0');
  const [isNewCashbackValue, setIsNewCashbackValue] = useState(true);
  const [systemCurrency, setSystemCurrency] = useState('CAD');

  const onNumericsBlur = useCallback(() => {
    const realCashbackValue = normalizeFloat(cashbackValue || '0');
    setCashbackValue(realCashbackValue);
  }, [cashbackValue]);
  const cashbackValueChangeHandler = useCallback((values) => {
    const { value } = values;
    const newCashbackValue = value ? normalizeFloat(value) : value;
    setCashbackValue(newCashbackValue);
  }, []);
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const res = await Promise.all([
          axios.get(`/api/system/${endpoint}`),
          axios.get('/api/system/system-currency'),
        ]);
        setIsNewCashbackValue(
          res[0].data === null ||
            res[0].data === undefined ||
            res[0].data === ''
        );
        setCashbackValue(res[0].data || '0');
        setSystemCurrency(res[1].data);
      } catch (err) {
        setError({ message: 'Cannot fetch system settings' });
        console.error(err);
      }
      setIsLoading(false);
    };
    setError({});
    fetchInitialData();
    return () => {};
  }, [endpoint]);
  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const cashbackValueToSubmit = cashbackValue || '0';
      const method = isNewCashbackValue ? 'post' : 'put';
      await axios[method](`/api/system/${endpoint}`, {
        [fieldName]: cashbackValueToSubmit,
      });
      setIsNewCashbackValue(false);
      setError({});
    } catch (error) {
      const baseError = { message: 'Error saving system settings' };
      console.error(error);
      if (error.response?.data) {
        setError({ ...error.response.data, ...baseError });
      } else {
        setError(baseError);
      }
    }
    setIsLoading(false);
  }, [cashbackValue, endpoint, fieldName, isNewCashbackValue]);
  return (
    <>
      <TextField
        name="adjustValue"
        value={cashbackValue}
        label={fieldLabel}
        onValueChange={cashbackValueChangeHandler}
        onChange={() => {}}
        type="number"
        format={null}
        decimalScale={2}
        allowNegative={false}
        suffix={` ${systemCurrency}`}
        isAllowed={isCashbackValueAllowed}
        className={css.editControl}
        disabled={isLoading}
        error={error?.[fieldName]}
        allowEmptyFormatting={true}
        onNumericBlur={onNumericsBlur}
      />
      {error?.message && <div className="error_msg">{error.message}</div>}
      {isLoading && <Spinner className={css.editSpinner} />}
      <Button
        onClick={handleSubmit}
        text="Save"
        needConfirm
        confirmationText={confirmationText}
        disabled={isLoading}
      ></Button>
    </>
  );
}
