import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

export default function CustomMUISelect({
  error,
  label,
  name,
  onChange,
  value,
  options,
  itemValue = 'value',
  itemLabel = 'label',
}) {
  return (
    <FormControl fullWidth sx={{ margin: '15px 0' }}>
      <InputLabel id={`${name}-select`}>{label}</InputLabel>
      <Select
        labelId={`${name}-select`}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
      >
        {options.map((op) => (
          <MenuItem key={op[itemValue]} value={op[itemValue]}>
            {op[itemLabel]}
          </MenuItem>
        ))}
      </Select>
      {error ? (
        <FormHelperText error={error}>This field is required</FormHelperText>
      ) : null}
    </FormControl>
  );
}
