import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "../../Layout/Header";
import Pagination from "../../Layout/Pagination";
import axios from "axios";
import BusinessSearch from "./BusinessSearch";
import BusinessList from "./BusinessList";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliates } from "src/redux/actions/affiliateActions";

const Businesses = () => {
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState({
    name: "",
    affiliate: null,
    orderType: { label: "Ascending", value: "ASC" },
    orderField: { label: "Name", value: "name" },
  });

  const [businesses, setBusinesses] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const affiliates = useSelector(state => state.affiliates);

  const getBusinesses = async (page, name, order, affiliateProgramId) => {
    setErrors({});
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('name', name || '');
    params.append('order', orderString);
    if(affiliateProgramId !== undefined) {
      params.append('affiliateProgramId', affiliateProgramId);
    }
    try {
      const res = await axios.get("/api/businesses", { params });
      setBusinesses(res.data.results);
      setTotalResults(res.data.totalResults);
    } catch (err) {
      setErrors(err.response.data);
      setTotalResults(0)
    }
  };

  useEffect(() => {
    if (!affiliates.affiliates) dispatch(getAffiliates());
    getBusinesses(page);
  }, []);

  const { affiliate, name, orderType, orderField } = search;
  const orderString = `${orderField.value},${orderType.value}`;
  const affiliateProgramId = affiliate ? affiliate.value : undefined;

  const changePage = (page) => {
    getBusinesses(page, name, orderString, affiliateProgramId);
    setPage(page++);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getBusinesses(1, name, orderString, affiliateProgramId);
    setPage(1);
  };

  return (
    <div>
      <Header
        title="Businesses"
        btnText="New Business"
        btnLink
        url="/dashboard/businesses/add"
      />

      <BusinessSearch
        handleSearch={handleSearch}
        setSearch={setSearch}
        search={search}
        affiliateProgramOptions={affiliates.affiliates || []}
      />

      <BusinessList businesses={businesses} errors={errors} />

      {totalResults ? (
        <Pagination
          totalRecords={totalResults}
          page={page}
          onPageChange={changePage}
        />
      ) : null}
    </div>
  );
};
export default Businesses;
