import React, { Component } from 'react';
import axios from 'axios';
import TextField from '../../Inputs/TextField';
import Select from '../../Inputs/Select';
import Button from '../../Inputs/Button';

export class BusinessSearch extends Component {
  handleChange = (e) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  handleSelectAffiliateProgram = (affiliate) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, affiliate });
  };

  handleSelectOrderType = (orderType) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, orderType });
  };

  handleSelectOrderField = (orderField) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, orderField });
  };

  render() {
    const { affiliate, name, orderType, orderField } = this.props.search;
    const { handleSearch, affiliateProgramOptions } = this.props;

    const orderTypeOptions = [
      { label: 'Ascending', value: 'ASC' },
      { label: 'Descending', value: 'DESC' },
    ];

    const orderFieldOptions = [
      { label: 'Name', value: 'name' },
      { label: 'Affiliate Network', value: 'affiliateProgramName' },
      { label: 'Start date', value: 'startDate' },
      { label: 'End date', value: 'endDate' },
    ];

    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
      backgroundColor: '#EBECF0',
      borderRadius: '2em',
      color: '#172B4D',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
      padding: '0.16666666666667em 0.5em',
      textAlign: 'center',
    };

    const formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );

    return (
      <form className="flex">
        <TextField
          name="name"
          value={name}
          placeholder="Search"
          className="input-sm"
          onChange={this.handleChange}
          maxLength={30}
        />
        <Select
          value={affiliate}
          name="affiliateProgramId"
          options={[
            { label: 'None', value: null },
            ...affiliateProgramOptions.map((el) => ({
              label: el.name,
              value: el.id,
            })),
          ]}
          className="input-sm"
          onChange={this.handleSelectAffiliateProgram}
          placeholder="Affiliate Network"
        />
        <Select
          value={orderField}
          name="orderField"
          options={orderFieldOptions}
          className="input-sm"
          onChange={this.handleSelectOrderField}
          clearable={false}
          placeholder="Sort by"
        />
        <Select
          value={orderType}
          name="orderType"
          options={orderTypeOptions}
          className="input-sm"
          onChange={this.handleSelectOrderType}
          clearable={false}
        />
        <Button text="Search" type="submit" onClick={handleSearch} />
      </form>
    );
  }
}

export default BusinessSearch;
