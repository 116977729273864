import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

export default function ConfirmDialog({message, confirm, onConfirm, onCancel}) {
    return (
      <Dialog open={confirm} maxWidth="lg">
        <DialogTitle>{message}</DialogTitle>
        <DialogActions>
          <Button onClick={onConfirm}>Confirm</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
}
