import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import SnackbarContext from 'src/context/SnackbarContext';
import isURL from 'validator/lib/isURL';
import {
  DEFAULT_IMAGE_CAROUSEL_ITEM,
  DEFAULT_PROGRAM_CAROUSEL_ITEM,
  spacingOptions,
} from '../../utils';
import ConfirmDialog from '../Dashboard/common/ConfirmDialog';
import Carousel from './components/Carousel';
import CustomMUISelect from './components/CustomMUISelect';
import Header from './components/Header';
import Image from './components/Image';
import Paragraph from './components/Paragraph';
import Poll from './components/Poll';
import ProgramCarousel from './components/ProgramCarousel';
import Quote from './components/Quote';
import Video from './components/Video';
import ZPattern from './components/ZPattern';
import BusinessCarouselModule from './modules/BusinessCarouselModule';
import ButtonModule from './modules/ButtonModule';
import CategoryCarouselModule from './modules/CategoryCarouselModule';
import DiscountCarouselModule from './modules/DiscountCarouselModule';
import HeaderModule from './modules/HeaderModule';
import ImageCarouselModule from './modules/ImageCarouselModule';
import ImageModule from './modules/ImageModule';
import ParagraphModule from './modules/ParagraphModule';
import PollModule from './modules/PollModule';
import ProgramCarouselModule from './modules/ProgramCarouselModule';
import QuoteModule from './modules/QuoteModule';
import VideoModule from './modules/VideoModule';
import ZPatternModule from './modules/ZPatternModule';

const BLACK = '#000';

const mapping = {
  businessCarousel: 'businesses',
  discountCarousel: 'discounts',
  categoryCarousel: 'items',
  imageCarousel: 'images',
  programCarousel: 'items',
};

const errorMapping = {
  text: (type) =>
    type === 'paragraph' || type === 'zPattern' ? 'Content' : 'Text',
  text_fr: (type) =>
    type === 'paragraph' || type === 'zPattern'
      ? 'Content (French)'
      : 'Text (French)',
  url: 'URL',
  image: 'Image',
  images: 'Images',
  size: 'Size',
  unit: 'Unit',
  item: 'Image',
  title: 'Title',
  item_fr: 'Image (French)',
  items: 'Items',
  pollId: 'Poll',
  orientation: 'Orientation',
};

const mediaItemKeyMapping = {
  id: 'mediaItem',
  id_fr: 'mediaItem_fr',
  backgroundImage: 'backgroundImageMedia',
  backgroundImageMobile: 'backgroundImageMobileMedia',
  image: 'imageItem',
  image_fr: 'imageItem_fr',
};

const defaultItemMapping = {
  imageCarousel: DEFAULT_IMAGE_CAROUSEL_ITEM,
  programCarousel: DEFAULT_PROGRAM_CAROUSEL_ITEM,
};

export default function Module({
  index,
  data,
  deleteItemFromBasket,
  error,
  clearModuleError,
  updateBasket,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [displayError, setDisplayError] = useState('');
  const [showCarouselErrors, setShowCarouselErrors] = useState(false);
  const [item, setItem] = useState({});
  const snackbar = useContext(SnackbarContext);

  const errorMessage = [];

  if (displayError && Object.keys(displayError).length > 0) {
    for (const error in displayError) {
      let field = '';
      if (errorMapping[error]) {
        field =
          typeof errorMapping[error] === 'function'
            ? errorMapping[error](item.type)
            : errorMapping[error];
      }
      errorMessage.push(
        <Alert severity="error" key={error}>
          {field ? `${field} - ` : ''}
          {displayError[error]}
        </Alert>
      );
    }
  }
  const type = mapping[item.type];

  useEffect(() => {
    setItem(data);
  }, [dialogOpen]);

  useEffect(() => {
    if (error) {
      setDisplayError(error);
    }
  }, [error]);

  useEffect(() => {
    if (clearModuleError) {
      setDisplayError('');
    }
  }, [clearModuleError]);

  const handleChange = (event) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };

  const handleCustomChange = (item) => {
    setItem(item);
  };

  const handleEntityChange = (event) => {
    setItem({ ...item, items: [], [event.target.name]: event.target.value });
  };

  const handleLimitChange = (event) => {
    const limit = event.target.value;
    if (item.variety === 'manual' || item.type === 'categoryCarousel') {
      if (parseInt(limit) < item[type].length) {
        const copiedArray = [...item[type]];
        copiedArray.splice(parseInt(limit));
        setItem({
          ...item,
          [event.target.name]: event.target.value,
          [type]: copiedArray,
        });
      } else {
        setItem({
          ...item,
          [event.target.name]: event.target.value,
        });
      }
      setShowCarouselErrors(false);
    } else {
      setItem({ ...item, [event.target.name]: event.target.value });
    }
  };

  const handleBusinessLimitChange = (event) => {
    const limit = event.target.value;
    if (item.variety === 'manual') {
      if (parseInt(limit) > item[type].length) {
        setItem({
          ...item,
          [event.target.name]: event.target.value,
        });
      } else {
        const copiedArray = [...item[type]];
        copiedArray.splice(parseInt(limit));
        setItem({
          ...item,
          [event.target.name]: event.target.value,
          [type]: copiedArray,
        });
      }
      setShowCarouselErrors(false);
    } else {
      setItem({ ...item, [event.target.name]: event.target.value });
    }
  };

  const handleImageLimitChange = (event) => {
    const limit = event.target.value,
      items = item[type];
    if (parseInt(limit) > items.length) {
      setItem({
        ...item,
        [event.target.name]: event.target.value,
        [type]: [
          ...items,
          ...Array(parseInt(limit) - items.length).fill(
            defaultItemMapping[item.type]
          ),
        ],
      });
    } else {
      const copiedImagesArray = [...items];
      copiedImagesArray.splice(parseInt(limit));
      setItem({
        ...item,
        [event.target.name]: event.target.value,
        [type]: copiedImagesArray,
      });
    }
    setShowCarouselErrors(false);
  };

  const handleImageSelected = (value, index, key) => {
    const copiedImagesArray = [...item[type]];
    copiedImagesArray[index] = {
      ...copiedImagesArray[index],
      [key]: value.id,
      [mediaItemKeyMapping[key]]: value,
    };
    setItem({
      ...item,
      [type]: copiedImagesArray,
    });
  };

  const swapItems = (list, index) => {
    const temp = list[index - 1];
    list[index - 1] = list[index];
    list[index] = temp;
    return list;
  };

  const handleMoveUpItem = (index) => {
    let listCopy = [...item[type]],
      newList;
    newList = swapItems(listCopy, index);
    setItem({
      ...item,
      [type]: newList,
    });
  };

  const handleMoveDownItem = (index) => {
    let listCopy = [...item[type]],
      newList;
    newList = swapItems(listCopy, index + 1);
    setItem({
      ...item,
      [type]: newList,
    });
  };

  const handleImagesChange = (value, index, key) => {
    const copiedImagesArray = [...item[type]];
    copiedImagesArray[index] = {
      ...copiedImagesArray[index],
      [key]: value,
    };
    setItem({
      ...item,
      [type]: copiedImagesArray,
    });
  };

  const handleChangeRemoveError = (event) => {
    setDisplayError(false);
    setItem({ ...item, [event.target.name]: event.target.value });
  };

  const handleRemoveItem = (index) => {
    const copiedArray = [...item[type]];
    copiedArray.splice(index, 1);
    setItem({
      ...item,
      [type]: copiedArray,
    });
  };

  const handleVarietyChange = (event) => {
    if (event.target.value === 'manual') {
      setItem({
        ...item,
        variety: event.target.value,
        [type]: [],
      });
    } else {
      setItem({
        ...item,
        variety: event.target.value,
        [type]: undefined,
      });
    }
  };

  const handleItemSelect = (value, index) => {
    const copiedArray = [...item[type]];
    copiedArray[index] = value.id;
    if (item.limit == item[type].length + 1) setShowCarouselErrors(false);
    setItem({
      ...item,
      [type]: copiedArray,
    });
  };

  const handleSave = () => {
    if (
      item.type === 'header' ||
      item.type === 'paragraph' ||
      item.type === 'quote'
    ) {
      if (!item.text) {
        setShowCarouselErrors(true);
        return;
      }
    }
    const isTwoColumn = item.layout === 'twoColumns';
    if (item.type === 'programCarousel') {
      if (
        item.items.some(
          (im) =>
            !im.backgroundImage ||
            !im.backgroundImageMobile ||
            !im.heading ||
            !im.heading.trim()
        )
      ) {
        snackbar.setSnackbarMessage("There's an error, please check your input.");
        setShowCarouselErrors(true);
        return;
      }
    }

    if (item.type === 'imageCarousel') {
      if (
        item.images.some(
          (im) =>
            !im.id || (isTwoColumn && !im.heading) || (im.url && !isURL(im.url))
        ) ||
        (isTwoColumn && (!item.title || !item.title.trim()))
      ) {
        snackbar.setSnackbarMessage("There's an error, please check your input.");
        setShowCarouselErrors(true);
        return;
      }
    }
    if (item.type === 'discountCarousel') {
      if (isTwoColumn && (!item.title || !item.title.trim())) {
        setShowCarouselErrors(true);
        return;
      }
    }
    if (item.type === 'poll') {
      if (!item.pollId) {
        setShowCarouselErrors(true);
        return;
      }
    }
    if (item.type === 'button') {
      if (!item.url || (item.url && !isURL(item.url)) || !item.text) {
        setShowCarouselErrors(true);
        return;
      }
    }
    if (item.type === 'video') {
      if (!item.url || (item.url && !isURL(item.url))) {
        setShowCarouselErrors(true);
        return;
      }
    }
    if (
      (item.type === 'discountCarousel' && item.variety === 'manual') ||
      (item.type === 'businessCarousel' && item.variety === 'manual') ||
      item.type === 'categoryCarousel'
    ) {
      if (item[type].length < item.limit) {
        setShowCarouselErrors(true);
        return;
      }
    }
    if (item.type === 'zPattern') {
      if (!item.image || !item.text) {
        setShowCarouselErrors(true);
        return;
      }
    }
    if (item.type === 'image') {
      if (!item.item || (item.unit !== 'auto' && !item.size)) {
        setShowCarouselErrors(true);
        return;
      }
    }
    setDialogOpen(false);
    updateBasket(index, item);
  };

  let component, edit;

  switch (data.type) {
    case 'header':
      component = (
        <Header
          id={data.id}
          text={data.text}
          text_fr={data.text_fr}
          type={data.type}
          size={data.size}
        />
      );
      edit = (
        <HeaderModule
          showError={showCarouselErrors}
          alignment={item.alignment}
          handleChange={handleChange}
          handleChangeRemoveError={handleChangeRemoveError}
          text={item.text}
          text_fr={item.text_fr}
          size={item.size}
        />
      );
      break;
    case 'paragraph':
      component = <Paragraph text={data.text} text_fr={data.text_fr} />;
      edit = (
        <ParagraphModule
          showError={showCarouselErrors}
          alignment={item.alignment}
          text={item.text}
          text_fr={item.text_fr}
          handleChange={handleChange}
          handleTextChange={(content) => {
            setDisplayError(false);
            setItem({ ...item, text: content });
          }}
          handleTextFRChange={(content) => {
            setItem({ ...item, text_fr: content });
          }}
        />
      );
      break;
    case 'quote':
      component = <Quote text={data.text} text_fr={data.text_fr} />;
      edit = (
        <QuoteModule
          showError={showCarouselErrors}
          alignment={item.alignment}
          text={item.text}
          text_fr={item.text_fr}
          handleChangeRemoveError={handleChangeRemoveError}
          handleChange={handleChange}
        />
      );
      break;
    case 'poll':
      component = <Poll pollId={data.pollId} />;
      edit = (
        <PollModule
          showError={showCarouselErrors}
          pollId={item.pollId}
          handleChange={handleChangeRemoveError}
        />
      );
      break;
    case 'imageCarousel':
      component = (
        <Carousel
          variety={data.variety}
          limit={data.limit}
          layout={data.layout}
        />
      );
      edit = (
        <ImageCarouselModule
          limit={item.limit}
          layout={item.layout}
          orientation={item.orientation}
          type={data.type}
          images={item.images}
          title={item.title}
          title_fr={item.title_fr}
          showError={showCarouselErrors}
          handleChange={handleChange}
          handleChangeRemoveError={handleChangeRemoveError}
          handleLimitChange={handleImageLimitChange}
          handleImageChange={handleImageSelected}
          handleImagesChange={handleImagesChange}
          handleRemoveItem={handleRemoveItem}
          handleMoveDownItem={handleMoveDownItem}
          handleMoveUpItem={handleMoveUpItem}
        />
      );
      break;
    case 'programCarousel':
      component = <ProgramCarousel limit={data.limit} items={data.items} />;
      edit = (
        <ProgramCarouselModule
          limit={item.limit}
          type={data.type}
          items={item.items}
          showError={showCarouselErrors}
          handleChange={handleChange}
          handleLimitChange={handleImageLimitChange}
          handleImageChange={handleImageSelected}
          handleImagesChange={handleImagesChange}
          handleRemoveItem={handleRemoveItem}
          handleMoveDownItem={handleMoveDownItem}
          handleMoveUpItem={handleMoveUpItem}
        />
      );
      break;
    case 'discountCarousel':
      component = (
        <Carousel
          variety={data.variety}
          limit={data.limit}
          layout={data.layout}
        />
      );
      edit = (
        <DiscountCarouselModule
          variety={item.variety}
          limit={item.limit}
          layout={item.layout}
          type={data.type}
          discounts={item.discounts}
          title={item.title}
          title_fr={item.title_fr}
          showError={showCarouselErrors}
          handleVarietyChange={(event) => {
            if (event.target.value === 'manual') {
              setItem({
                ...item,
                variety: event.target.value,
                discounts: [],
              });
            } else {
              setItem({
                ...item,
                variety: event.target.value,
                discounts: undefined,
                layout:
                  item.variety === 'manual' && item.layout === 'twoColumns'
                    ? 'smallThumbnails'
                    : item.layout,
              });
            }
          }}
          handleChange={handleChange}
          handleChangeRemoveError={handleChangeRemoveError}
          handleLimitChange={handleLimitChange}
          handleDiscountChange={(value, index) => {
            const copiedDiscountsArray = [...item.discounts];
            copiedDiscountsArray[index] = {
              id: value.id,
              name: value.name,
              color: BLACK,
            };
            if (item.limit == item.discounts.length + 1)
              setShowCarouselErrors(false);
            setItem({
              ...item,
              discounts: copiedDiscountsArray,
            });
          }}
          handleColorChange={(value, index) => {
            const copiedDiscountsArray = [...item.discounts];
            copiedDiscountsArray[index] = {
              ...copiedDiscountsArray[index],
              color: value,
            };
            setItem({
              ...item,
              discounts: copiedDiscountsArray,
            });
          }}
          handleRemoveItem={handleRemoveItem}
          handleMoveDownItem={handleMoveDownItem}
          handleMoveUpItem={handleMoveUpItem}
        />
      );
      break;
    case 'categoryCarousel':
      component = (
        <Carousel
          variety={data.entity}
          limit={data.limit}
          layout={data.layout}
        />
      );
      edit = (
        <CategoryCarouselModule
          entity={item.entity}
          limit={item.limit}
          layout={item.layout}
          type={data.type}
          items={item.items}
          showError={showCarouselErrors}
          handleChange={handleChange}
          handleEntityChange={handleEntityChange}
          handleLimitChange={handleLimitChange}
          handleItemSelect={handleItemSelect}
          handleRemoveItem={handleRemoveItem}
          handleMoveDownItem={handleMoveDownItem}
          handleMoveUpItem={handleMoveUpItem}
        />
      );
      break;
    case 'businessCarousel':
      component = (
        <Carousel
          variety={data.variety}
          limit={data.limit}
          layout={data.layout}
        />
      );
      edit = (
        <BusinessCarouselModule
          variety={item.variety}
          limit={item.limit}
          layout={item.layout}
          type={data.type}
          businesses={item.businesses}
          showError={showCarouselErrors}
          handleChange={handleChange}
          handleVarietyChange={handleVarietyChange}
          handleLimitChange={handleLimitChange}
          handleBusinessChange={handleItemSelect}
          handleRemoveItem={handleRemoveItem}
          handleMoveDownItem={handleMoveDownItem}
          handleMoveUpItem={handleMoveUpItem}
        />
      );
      break;
    case 'image':
      component = <Image image={item.mediaItem} />;
      edit = (
        <ImageModule
          item={item}
          showError={showCarouselErrors}
          handleChange={handleChange}
          handleCustomChange={handleCustomChange}
          handleImageChange={(image, name) => {
            setDisplayError(false);
            setItem({ ...item, item: image.id, mediaItem: image });
          }}
          handleFrenchImageChange={(image, name) => {
            setItem({ ...item, item_fr: image.id, mediaItem_fr: image });
          }}
        />
      );
      break;
    case 'zPattern':
      component = (
        <ZPattern
          image={item.mediaItem}
          text={data.text}
          text_fr={data.text_fr}
        />
      );
      edit = (
        <ZPatternModule
          item={item}
          showError={showCarouselErrors}
          handleChange={handleChange}
          handleTextChange={(content) => {
            setItem({ ...item, text: content });
          }}
          handleTextFRChange={(content) => {
            setItem({ ...item, text_fr: content });
          }}
          handleImageChange={(image, name) => {
            setItem({ ...item, image: image.id, mediaItem: image });
          }}
          handleFrenchImageChange={(image, name) => {
            setItem({ ...item, image_fr: image.id, mediaItem_fr: image });
          }}
        />
      );
      break;
    case 'video':
      component = <Video url={data.url} />;
      edit = (
        <VideoModule
          showError={showCarouselErrors}
          alignment={item.alignment}
          url={item.url}
          handleChange={handleChangeRemoveError}
        />
      );
      break;
    default:
      component = (
        <Button href={data.url} variant="contained">
          {data.text ? data.text : 'Add button'}
        </Button>
      );
      edit = (
        <ButtonModule
          showError={showCarouselErrors}
          alignment={item.alignment}
          text={item.text}
          text_fr={item.text_fr}
          url={item.url}
          handleChange={handleChange}
          handleChangeRemoveError={handleChangeRemoveError}
        />
      );
      break;
  }

  const deleteIcon = (
    <IconButton onClick={() => setConfirm(true)}>
      <DeleteIcon />
    </IconButton>
  );

  return (
    <>
      <Card
        variant={'outlined'}
        sx={{
          marginBottom: 2,
          border:
            Array.isArray(errorMessage) && errorMessage.length
              ? '1px solid #ef5350'
              : '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <CardHeader subheader={data.type} action={deleteIcon} />
        <CardActionArea onClick={() => setDialogOpen(true)}>
          <CardContent>{component}</CardContent>
        </CardActionArea>

        <Dialog
          fullWidth
          maxWidth="md"
          onClose={() => setDialogOpen(false)}
          open={dialogOpen}
        >
          <DialogTitle onClose={() => setDialogOpen(false)}>
            Edit {data.type} {errorMessage}
          </DialogTitle>
          <DialogContent>
            <Stack
              key={index}
              direction="row"
              spacing={2}
              alignItems="baseline"
              justifyContent="space-between"
            >
              <CustomMUISelect
                name="topSpacing"
                label="Top Spacing"
                value={item.topSpacing}
                onChange={handleChange}
                options={spacingOptions}
              />
              <CustomMUISelect
                name="bottomSpacing"
                label="Bottom Spacing"
                value={item.bottomSpacing}
                onChange={handleChange}
                options={spacingOptions}
              />
            </Stack>
            {edit}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
            <Button onClick={() => setDialogOpen(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <ConfirmDialog
          confirm={confirm}
          message="Confirm deletion?"
          onConfirm={() => {
            setConfirm(false);
            deleteItemFromBasket(index);
          }}
          onCancel={() => setConfirm(false)}
        />

        {errorMessage}
      </Card>
    </>
  );
}
