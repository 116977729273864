import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../utils';
import axios from 'axios';
import Pagination from '../../Layout/Pagination';
import List from './List';
import { Button } from '@mui/material';
import moment from 'moment';

import Select from '../../Inputs/Select';
import { getCategories } from '../../../redux/actions/categoryActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const LocalBusinessDashboard = ({ match, history, getCategories }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState({ field: 'name', asc: true });
  const [statusFilter, setStatusFilter] = useState(null);
  const [data, setData] = useState([]);

  const { params } = match;

  const pageLimit = 25;

  const fetchData = async (page, csv) => {
    const url = '/api/subscriptions/';
    const params = {
      page,
      limit: pageLimit,
      status: statusFilter && statusFilter.value,
      order: `${sortBy.field},${sortBy.asc ? 'ASC' : 'DESC'}`,
      csv: csv,
      // offset,
    };
    try {
      const res = await axios.get(url, { params });

      if (csv) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `subscriptions-export-${moment().format('MM-DD-YYYY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
      } else {
        setData(res.data.rows);
        setTotalResults(res.data.count);
        setErrors({});
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setErrors(err.response.data);
    }
  };

  useEffect(() => {
    getCategories(params.page ? params.page : undefined);
    fetchData();
  }, []);

  useEffect(() => {
    changePage(1);
  }, [statusFilter, JSON.stringify(sortBy)]);

  const columns = [
    { label: 'Business Name', name: 'name' },
    { label: 'Signup Date', name: 'signupDate', type: 'date' },
    { label: 'Approval Date', name: 'approvalDate', type: 'date' },
    { label: 'Last Payment Date', name: 'lastPaymentDate', type: 'date' },
    { label: 'Next Payment Date', name: 'nextPaymentDate', type: 'date' },
    { label: 'Status', name: 'status' },
  ];

  const statusFilterOptions = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'Subscribed', value: 'Subscribed' },
    { label: 'Cancelled', value: 'Cancelled' },
  ];

  const changePage = (page) => {
    fetchData(page);
    setPage(page);
    history.push(`/dashboard/local-business-subscriptions/${page}`);
  };

  const setSort = (sort) => {
    setSortBy({ field: sort, asc: !sortBy.asc });
  };

  const renderPagination = () => {
    if (
      (!isEmpty(errors) && errors.message === 'No data found') ||
      !totalResults
    ) {
      return null;
    } else
      return (
        <Pagination
          totalRecords={totalResults}
          page={params.page ? params.page : '1'}
          onPageChange={changePage}
          pageLimit={pageLimit}
        />
      );
  };

  return (
    <div>
      <h1>Local Business Subscriptions</h1>

      <Select
        onChange={(v) => setStatusFilter(v)}
        name="statusFilter"
        label="Status"
        value={statusFilter}
        options={statusFilterOptions}
      />
      <Button onClick={() => fetchData(1, true)}>Export</Button>

      <List
        columns={columns}
        setSort={setSort}
        sortBy={sortBy}
        data={data}
        loading={loading}
        errors={errors}
        fetchData={fetchData}
        params={params}
      />
      {renderPagination()}
    </div>
  );
};

LocalBusinessDashboard.propTypes = {};

const mapDispatchToProps = {
  getCategories,
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(LocalBusinessDashboard));
