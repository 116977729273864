import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import DialogTitle from '@mui/material/DialogTitle';
import ViewSubscription from './ViewSubscription';
import Dialog from '@mui/material/Dialog';

const ListItem = ({ data, columns, columnWidth, fetchData, params }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const renderData = () => {
    if (columns) {
      return columns.map((el) => {
        let text = data[el.name] && `${data[el.name]}`;

        if (typeof data[el.name] === 'object' && !el.type)
          text = data[el.name]?.label;

        if (el.type === 'datetime')
          text = data[el.name]
            ? `${moment(data[el.name]).format('YYYY-MM-DD')} at 
          ${moment(data[el.name]).format('HH:mm')}`
            : '';

        if (el.type === 'date')
          text = data[el.name]
            ? `${moment(data[el.name]).format('YYYY-MM-DD')}`
            : '';

        if (el.type === 'username')
          text =
            data[el.name] &&
            `${data[el.name]?.firstName} ${data[el.name]?.lastName} (${
              data[el.name]?.email
            })`;

        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setDialogOpen(true)}
            title={text}
          >
            {text ? text : 'NA'}
          </div>
        );
      });
    }
  };

  return (
    <StyledListItem columnWidth={columnWidth}>
      {renderData()}

      <DialogWrapper
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth={'xl'}
      >
        <Title>
          <span>Subscription details</span> <span>{data.id}</span>
        </Title>
        <ViewSubscription
          id={data.id}
          setParentDialogOpen={setDialogOpen}
          fetchData={fetchData}
          page={params.page ? params.page : 1}
        />
      </DialogWrapper>
    </StyledListItem>
  );
};

ListItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ListItem;

const StyledListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    width: ${(p) => (p.columnWidth ? p.columnWidth : '12.5%')};
    word-break: break-word;
    line-height: 150%;
    padding: 5px;

    word-break: normal;
    overflow-y: auto;

    ::-webkit-scrollbar {
      height: 7px; /* height of horizontal scrollbar ← You're missing this */
      width: 4px; /* width of vertical scrollbar */
    }
    ::-webkit-scrollbar-thumb {
      background: #cfcfcf;
      border-radius: 10px;
    }
  }
`;

const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    padding: 50px;
  }
`;

const Title = styled(DialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #44525a;
  color: #fff;
  border-radius: 5px;

  &.MuiDialogTitle-root {
    font-weight: 500;
    padding: 8px;
  }
`;
