import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import css from "./DropdownList.module.scss";
import List from "../../common/List";
import DropdownItem from "./DropdownItem";
import Pagination from "../../../Layout/Pagination";
import { isEmpty } from "../../../../utils";

function DropdownList({ tab, match, history }) {
  const [data, setData] = useState([]);
  const [totalResults, setTotalResults] = useState(null);
  const [err, setErr] = useState(null);

  const { page } = match.params;

  const getData = useCallback(async (page) => {
    setErr(null);
    try {
      const res = await axios.get(`/api/${tab.value}/`, { params: { page } });
      setData(res.data.results);
      setTotalResults(res.data.totalResults);
    } catch (err) {
      setErr(err.response.data);
      if (err.response.status === 404) {
        setData([]);
      }
    }
  }, [tab.value]);

  useEffect(() => {
    getData();
    return () => {
      setData([]);
      setTotalResults(null);
    };
  }, [getData, tab.value]);

  const changePage = (page) => {
    getData(page);
    history.push(`/dashboard/dropdowns/${tab.value}/${page}`);
  };

  return (
    <div className={css.container}>
      <h2>{tab.label}</h2>
      <DropdownItem getData={getData} tab={tab} />
      <br />
      <p style={{ color: "#ef4e4e" }}>{!isEmpty(err) ? err.message : null}</p>
      <List data={data} ent={<DropdownItem getData={getData} tab={tab} />} />

      {totalResults ? (
        <Pagination
          totalRecords={totalResults}
          page={page ? page : "1"}
          onPageChange={changePage}
        />
      ) : null}
    </div>
  );
}

DropdownList.propTypes = {};

export default withRouter(DropdownList);
